import React from "react";
import Social from "../Social";
import Testimonials from "../testimonial/TestimonialAnimation";
import Awards from "../award/AwardsAnimation";
import Facts from '../facts/FactsAnimation';

const settings = {
  dots: true,
  infinite: true,
  slidesToShow: 4,
  slidesToScroll: 1,
};

const photos = [
  { link: 'https://edu.gov.ru/', img: 'img.png' },
  { link: 'https://krao.ru/', img: 'img_1.png' },
  { link: 'https://kipk.ru/', img: 'img_6.png' },
  { link: 'http://www.inclusive-edu.ru/', img: 'img_7.png' },
  { link: 'https://nra-russia.ru/', img: 'img_8.png' },
];

const About = () => {
  return (
    <>
      <section id="about" className="section theme-light dark-bg">
        <div className="container">
          <div className="row align-items-center justify-content-center">
            <div
              className="col-md-6 col-lg-4"
              data-aos="fade-up"
              data-aos-duration="1200"
            >
              <div className="about-me">
                <div className="img">
                  <div className="img-in">
                    <img src="img/about/about-me.jpg" alt="about"/>
                  </div>

                  <Social/>

                  {/* End social icon */}
                </div>
                {/* End img */}
                <div className="info">
                  <p>Учитель-логопед</p>
                  <h3>Зинарева Алла Камильевна</h3>
                </div>
                {/* End info */}
              </div>
              {/* End about-me */}
            </div>
            {/* End col */}

            <div
              className="col-lg-7 ml-auto"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="200"
            >
              <div className="about-info">
                <div className="title">
                  <h3>Обо мне</h3>
                </div>
                <div className="about-text">
                </div>
                <div className="info-list">
                  <div className="row">
                    <div className="col-sm-6">
                      <ul>
                        <li>
                          <label>ФИО: </label>
                          <span>Зинарева Алла Камильевна</span>
                        </li>
                        <li>
                          <label>Место работы:</label>
                          <span>МБДОУ муниципальное бюджетное дошкольное образовательное учреждение Балахтинский детский сад № 5 «Солнышко»</span>
                        </li>
                        <li>
                          <label>Должность:</label>
                          <span>учитель-логопед</span>
                        </li>
                        <li>
                          <label>Квалификационная категория: </label>
                          <span>высшая</span>
                        </li>
                        <li>
                          <label>Опыт работы: </label>
                          <span>учитель-логопед в Балахтинском детском саду № 5 «Солнышко» - c 01.09.2001 года по настоящее время</span>
                        </li>
                        <li>
                          <p><a target={'_blank'} rel={'noreferrer'} href={'https://universityofchildhood.ru'}>Университет
                            Детства</a></p>
                          <p><a target={'_blank'} rel={'noreferrer'} href={'https://vk.com/club201274705'}>Кампус
                            Красноярского края</a></p>
                        </li>
                      </ul>
                    </div>
                    <div className="col-sm-6">
                      <ul>
                        <li>
                          <label>Образование:</label>
                          <span>Красноярский государственный педагогический университет им В. П. Астафьева. Присуждена квалификация: учитель и логопед школ для детей с нарушением интеллекта по специальности «Олигофренопедагогика» с дополнительной специальностью «Логопедия» (2000 г)</span>
                        </li>
                        <li>
                          <span>Региональный лидер и организатор Кампуса Красноярского края фонда «Университет детства» </span>
                        </li>
                        <li>
                          <label>Письма регионального представителя и организатора Кампуса Красноярского края сообщества "Университет Детства":</label>
                          <div><a target={'_blank'} rel={'noreferrer'}
                                href={'https://cloud.mail.ru/public/GVRc/rUYcRbSre'}>2021 г.</a></div>
                          <div><a target={'_blank'} rel={'noreferrer'}
                                href={'https://cloud.mail.ru/public/tcLh/yZSAFTMiw'}>2022 г.</a></div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
              {photos.map((val, i) => (
                <div className="col-2">
                  <a target={'_blank'} rel={'noreferrer'} href={val.link}>
                    <img className="d-block"
                      src={`img/kray/${val.img}`}
                      alt="review comments"
                    ></img>
                  </a>
                </div>
              ))}
            </div>
            {/* End col */}
          </div>

          {/* separated */}

          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>

          <div className="title">
            <h3>Познакомимся поближе</h3>
          </div>

          <Facts/>

          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>Мои последние достижения</h3>
          </div>

          <Awards/>
          {/* End Awards */}

          {/* separated */}
          <div
            className="separated"
            style={{
              backgroundImage: `url(${
                process.env.PUBLIC_URL + "img/border-dark.png"
              })`,
            }}
          ></div>
          {/* End separated */}

          <div className="title">
            <h3>Секреты успеха</h3>
          </div>

          <Testimonials/>
          {/* End Testimonaial */}
        </div>
      </section>
    </>
  );
};

export default About;
