import React from "react";

const AwardContnet = [
  {
    img: "a1",
    awardName: "Победитель",
    awardFor: "Конкурс имени Льва Выготского (2022 г)",
    href: "https://detsad-shop.ru/stati/mezhdunarodnyy-festival-deti-radugi-socializaciya-i-razvitie-kommunikativnyh-sposobnostey-2022.html",
    delayAnimation: "0",
  },
  {
    img: "a1",
    awardName: "Лауреат",
    awardFor: "Краевой конкурс \"Воспитатель года\" (2022 г)",
    href: "https://detsad-shop.ru/stati/mezhdunarodnyy-festival-deti-radugi-socializaciya-i-razvitie-kommunikativnyh-sposobnostey-2022.html",
    delayAnimation: "200",
  },
  {
    img: "a1",
    awardName: "2 место регионального этапа",
    awardFor: "Воспитатели России (2022 г)",
    href: "https://detsad-shop.ru/stati/mezhdunarodnyy-festival-deti-radugi-socializaciya-i-razvitie-kommunikativnyh-sposobnostey-2022.html",
    delayAnimation: "400",
  },
  {
    img: "a1",
    awardName: "Участник",
    awardFor: "Летней онлайн-школы 2022, Лидерской  школы  2022 от Университет детства",
    href: "https://detsad-shop.ru/stati/mezhdunarodnyy-festival-deti-radugi-socializaciya-i-razvitie-kommunikativnyh-sposobnostey-2022.html",
    delayAnimation: "600",
  },
  {
    img: "a1",
    awardName: "Эксперт",
    awardFor: "Краевого педагогического Хакатона 2022",
    href: "https://detsad-shop.ru/stati/mezhdunarodnyy-festival-deti-radugi-socializaciya-i-razvitie-kommunikativnyh-sposobnostey-2022.html",
    delayAnimation: "800",
  },
  {
    img: "a1",
    awardName: "Победитель I степени",
    awardFor: "Международный фестиваль «Дети радуги: социализация и развитие коммуникативных способностей» (2021 г)",
    href: "https://detsad-shop.ru/stati/mezhdunarodnyy-festival-deti-radugi-socializaciya-i-razvitie-kommunikativnyh-sposobnostey-2022.html",
    delayAnimation: "1000",
  },
  {
    img: "a2",
    awardName: "Победитель",
    awardFor: "Международный  Конкурс стипендий и грантов имени Л. С. Выготского (2019 и 2017 г)",
    href: "https://konkurs.rybakovfond.ru/",
    delayAnimation: "1200",
  },
  {
    img: "a3",
    awardName: "Активный участник",
    awardFor: "Лидерская школа и Эльбрусская Переговорная Площадка (2021 г)",
    delayAnimation: "1400",
  },
  {
    img: "a1",
    awardName: "Победитель",
    awardFor: "Краевой фестиваль лучших инклюзивных практик  «Комплексное психолого-педагогическое сопровождение семей и детей в условиях инклюзивного образования (2021 г.)",
    delayAnimation: "1600",
  },
];

const Awards = () => {
  return (
    <>
      <div className="row">
        {AwardContnet.map((val, i) => (
          <div
            className="col-lg-4 m-15px-tb"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="feature-box-02 d-flex align-items-center">
              <div className="icon">
                <a
                  href={val.href}
                  target="_blank"
                  rel="noreferrer"
                >
                <img src={`img/award/${val.img}.png`} alt="award" />
                </a>
              </div>
              <div className="media-body">
                <h6>{val.awardName}</h6>
                <p>{val.awardFor}</p>
              </div>
            </div>
          </div>
          // End .col
        ))}
      </div>
      {/* End .row */}
    </>
  );
};

export default Awards;
