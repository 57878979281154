import React from "react";

const Parents = () => {
  return (
    <>
      <section id="parents">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 ">
              <p>Здравствуйте, уважаемые родители!</p>

              <p>Спасибо, что вы заинтересованы речевым развитием ваших детей.</p>

              <p>Своевременное и полноценное формирование речи в дошкольном детстве &ndash; одно из основных условий
                развития ребёнка и его успешного обучения в школе.</p>

              <p>Большинство детей овладевает правильным произношением всех речевых звуков ещё в дошкольном возрасте без
                специального обучения, на основе подражания правильной речи окружающих людей. Но у многих детей, по тем
                или иным причинам, картина иная.</p>

              <p>Важно не упустить драгоценное время, надеясь на то, что &laquo;с возрастом всё само пройдёт&raquo;. О
                том, что эти надежды далеко не всегда оправдываются, свидетельствует наличие дефектов звукопроизношения
                в речи многих взрослых людей.</p>

              <p>Чаще всего задержка развития речи тяжело сказывается на общем развитии ребёнка, не позволяет ему
                полноценно общаться и играть со сверстниками, правильно выразить свои мысли, затрудняет познание
                окружающего мира, отягощает эмоционально-психическое состояние ребёнка. Письменная речь формируется на
                основе устной и некоторые дефекты в произношении звуков в дальнейшем неизбежно отражаются на письме.</p>

              <p>На базе детского сада № 5 &laquo;Солнышко&raquo; функционирует логопедическая
                группа &laquo;Радуга&raquo; с целью оказания помощи детям с тяжёлыми нарушенияями речи.</p>

              <p>Если у Вас есть вопросы по речевому развитию ребёнка, Вы можете обратиться к педагогам своей группы, а
                также ко мне по указанным на сайте контактам.</p>

              <p>Комплектование логопедической группы ведет территориальная <a
                href="http://balaxtayo.ucoz.net/index/pmpk/0-65">психолого-медико-педагогическая комиссия
                (ТПМПК) </a></p>

              <p>Логопедическая работа в группе строится на основе учёта этиологии, симптоматики нарушения, структуры
                речевого дефекта, возрастных и индивидуальных особенностей ребенка, а также уровня развития речи,
                моторики и познавательной деятельности детей.</p>

              <p>Важна комплексность.</p>
              <div className="w-50">
                <img
                  src={`img/parents.jpg`}
                  alt="intro page"
                ></img>
              </div>
              <p>Вы многое можете сделать для своего ребёнка.</p>

              <p>На страничках сайта Вы найдёте советы, игры, упражнения, речевой материал для занятий с детьми<br/></p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Parents;
