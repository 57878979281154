import React from "react";
import {
  FaFacebookF,
  FaTwitter,
  FaInstagram,
  FaLinkedinIn,
  FaVk,
} from "react-icons/fa";

const SocialShare = [
];

const Social = () => {
  return (
    <div className="nav social-icons justify-content-center">
      {SocialShare.map((val, i) => (
        <a key={i} href={`${val.link}`} rel="noreferrer" target="_blank" >
          {val.Social}
        </a>
      ))}
    </div>
  );
};

export default Social;
