import React from "react";
import Skills from "../skills/Skills";

const Children = () => {
  return (
    <>
      <section id="resume" className="container">
        <div className="container site-content no-sidebar no-padding">
          <div className="row g-5">
            <div className="col-lg-4 col-12">

              <figure className="wp-block-image"><a href="https://pochemu4ka.ru/" target="_blank"
                                                    rel="noopener"><img
                src="img/children/children3.jpg"
                alt="Почемучка"/></a></figure>


              <p><a href="https://www.igraemsa.ru/" target="_blank" rel="noreferrer noopener"></a></p>


              <p>Сайт “Почемучка” - развивающие праздники, игры, ответы на вопросы</p>
            </div>

            <div className="col-lg-4 col-12">

              <figure className="wp-block-image"><a href="http://razvitiedetei.info/" target="_blank"
                                                    rel="noopener"><img
                src="img/children/children6.jpg"
                alt="Развитие детей"/></a></figure>


              <p><a href="http://razvitiedetei.info/" target="_blank" rel="noreferrer noopener"></a></p>


              <p>Сайт «Развитие детей» - обзоры развивающих игрушек и книг, рекомендации дидактических пособий,
                описания упражнений, опыты, праздники….</p>
            </div>

            <div className="col-lg-4 col-12">
              <figure className="wp-block-image"><a href="https://kids-smart.ru/" target="_blank"
                                                    rel="noopener"><img
                src="img/children/children9.jpg"
                alt="Кидс-смарт"/></a></figure>


              <p><a href="https://kids-smart.ru/" target="_blank" rel="noreferrer noopener"></a></p>


              <p>Кидс-смарт - развивающие онлайн игры и упражнения для детей по темам занятий. В каждой теме находятся
                упражнения для дошкольников и школьников разного возраста</p>
            </div>

            <div className="col-lg-4 col-12">
              <figure className="wp-block-image"><a href="https://chudo-udo.info/" target="_blank"
                                                    rel="noopener"><img
                src="img/children/children1.jpg"
                alt="Чудо-юдо"/></a></figure>


              <p><a href="https://chudo-udo.info/" target="_blank" rel="noreferrer noopener"></a></p>


              <p>Портал “Чудо- юдо” - кроссворды, ребусы, , скороговорки, лабиринты, поделки и пазлы, аудиосказки, и
                прописи…</p>
            </div>

            <div className="col-lg-4 col-12">
              <figure className="wp-block-image"><a href="https://wikium.ru/" target="_blank"
                                                    rel="noopener"><img
                src="img/children/children4.jpg"
                alt="Викиум"/></a></figure>


              <p><a href="https://wikium.ru/" target="_blank" rel="noreferrer noopener"></a></p>


              <p>Тренажеры для мозга Викиум, в основе которых лежат методики российских и зарубежных нейропсихологов,
                доказавшие свою эффективность в ходе множества экспериментов и научных работ</p>
            </div>

            <div className="col-lg-4 col-12">

              <figure className="wp-block-image"><a href="https://tillionline.ru/" target="_blank"
                                                    rel="noopener"><img
                src="img/children/children10.jpg"
                alt="Дошкола Тилли"/></a></figure>


              <p><a href="https://tillionline.ru/" target="_blank" rel="noreferrer noopener"></a></p>


              <p>Дошкола Тилли – образовательная онлайн-платформа для всестороннего развития: буквы и слоги, чтение и
                развитие речи, цифры и счет, логика и память, окружающий мир, логопедические задания, подготовка к
                школе</p>
            </div>

            <div className="col-lg-4 col-12">
              <figure className="wp-block-image"><a href="https://arch.rgdb.ru/xmlui/" target="_blank"
                                                    rel="noopener"><img
                src="img/children/children7.jpg"
                alt="Национальная электронная детская библиотека (НЭДБ) – кладезь материалов"/></a></figure>


              <p><a href="https://arch.rgdb.ru/xmlui/" target="_blank" rel="noreferrer noopener"></a></p>


              <p>Национальная электронная детская библиотека (НЭДБ) – кладезь материалов</p>
            </div>

            <div className="col-lg-4 col-12">
              <figure className="wp-block-image"><a href="https://potomy.ru/" target="_blank"
                                                    rel="noopener"><img
                src="img/children/children8.jpg"
                alt="Потому.ру"/></a></figure>


              <p>Потому.ру — это детская энциклопедия онлайн, которая содержит в себе огромное количество материала по
                детской тематике, а также тысячи ответов на детские вопросы </p>
            </div>

            <div className="col-lg-4 col-12">
              <figure className="wp-block-image"><a href="https://chudesenka.ru/" target="_blank"
                                                    rel="noopener"><img
                src="img/children/children5.jpg"
                alt="c6LEacqsyN4.jpg"/></a></figure>


              <p><a href="https://chudesenka.ru/" target="_blank" rel="noreferrer noopener"></a></p>


              <p>Сайт “Чудесенка” - стихи и песни, игры, кулинарные уроки, праздники…</p>
            </div>

            <div className="col-lg-4 col-12">
              <figure className="wp-block-image"><a href="http://razvitiedetei.info/" target="_blank"
                                                    rel="noopener"><img
                src="img/children/children2.jpg"
                alt="Сайт «Развитие детей» - обзоры  развивающих игрушек и книг"/></a></figure>
              <p><a href="http://razvitiedetei.info/" target="_blank" rel="noreferrer noopener"></a></p>
              <p>Сайт «Развитие детей» - обзоры развивающих игрушек и книг, рекомендации дидактических пособий,
                описания упражнений, опыты, праздники….</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Children;
