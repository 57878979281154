import React from "react";
import Slider from 'react-slick';
import Services from "../service/ServiceAnimation";

const settings = {
  dots: true,
  infinite: true,
  speed: 500,
  slidesToShow: 2,
  slidesToScroll: 1,
};

const resumeContent = [
  {
    compnayName: 'Кейс "Дружная семья',
    jobDescription: `А ещё у нас живёт дружная семья медведей Потаповых.
Они помогут мне  рассказать  вам об авторском  предметно-игровом кейсе из подручных материалов.
Знакомьтесь:
Папа Алексей Потапыч, мама Светлана Ивановна. У них трое детей: два мальчика и девочка. Паше 3 года, Диме - 6 лет, Маше - 13 лет. Недавно Алексей Потапыч и Светлана Ивановна купили просторную трёхэтажную берлогу, светлую, добротную, но там было совершенно пусто, негде спать, кушать, играть, делать уроки, отдыхать. Как вы думаете, уютно будет им там жить? Семья Потаповых очень просит о помощи, всех помощников она потом пригласит на новоселье и угощение. Дети, а вы хотите поучаствовать в ремонте берлоги, благоустроить её, подобрать мебель для этой замечательной семьи? Попробуем разработать дизайнерский проект? Вы хотите попробовать побыть дизайнерами интерьера?
Важно, что дети изучают ситуацию, разбираются в проблеме,  затем предлагают возможные пути решения, и самостоятельно или совместно со взрослым, выбирают самый оптимальный путь выхода из проблемы.
Очень ценен сам процесс обсуждения, дискуссия, когда в рассуждении рождается решение.  Дети через проблемные вопросы, через обсуждения, взаимодействие друг с другом начинают решать кейсовые задачи.
`,
    delayAnimation: "500",
    photos: [
      {
        img: "teacherFind/case/1.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/case/2.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/case/3.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/case/4.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/case/5.jpg",

        delayAnimation: "",
      },
    ],
  },
  {
    compnayName: "Поиграем на полу",
    jobDescription: `Победив в международом фестивале «Дети радуги: социализация и развитие коммуникативных способностей», я получила в подарок от российской компании VAY TOY "Играю, двигаюсь, учусь",
     развивающую напольную интерактивную игру для детей «Форма, цвет, размер» разработанную Татьяной и Марком  Вайнапель,  помогающую  ребёнку в двигательной деятельности познавать основные цвета, фигуры, метрические соотношения между объектами, их пространственное положение на плоскости. 
Я придумала  игровые упражнения на развитие фонематического восприятия,  звукового анализа и синтеза, связной речи, формирование грамматического строя речи, посредством которых  можно  решать   познавательные задачи совместно с детьми, поддерживая их инициативу и позволяя самим реализовывать собственный замысел.
`,
    delayAnimation: "700",
    photos: [
      {
        img: "teacherFind/floor/1.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/floor/2.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/floor/3.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/floor/4.jpg",

        delayAnimation: "",
      },
    ],
  },
  {
    compnayName: "Речецветия",
    jobDescription: `В стране «Речецветии» живут звуковые человечки.
(Используются элементы программы Г Ванюхиной «Речецветик»)
Шесть гласных звуков - шесть девочек в ярких красных платьях.
Вместе с ребятами девочки поют красивые песенки [у, а, и, э, о, ы]
Согласные звуки - мальчики. Они не поют, но свистят, шипят, вибрируют, взрываются, их можно потянуть [ж - ж - ж], но не пропеть.
У мальчиков рубашки разного цвета, а ещё у кого-то из них есть колокольчики.
При знакомстве с каждым новым согласным звуком дети должны уточнить положение губ и языка: подражая взрослому, глядя в зеркало (самоконтроль) и друг на друга (взаимоконтроль).
Работа ведется по автоматизации и дифференциации поставленных звуков изолированно, в слогах, словах и в спонтанной речи.
Дети производят звуко-слоговой анализ и синтез, создают звуковые и слоговые дорожки, используя дома, корабли, поезд с вагонами и много картинок…
Подробнее о «Речецветии» по ссылке
`,
    delayAnimation: "400",
    photos: [
      {
        img: "teacherFind/flower/1.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/flower/2.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/flower/3.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/flower/4.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/flower/5.jpg",

        delayAnimation: "",
      },
    ],
  },
  {
    compnayName: "Музыкант",
    jobDescription: `Музыка воспринимается эмоционально, она имеет огромное значение в развитии чувств ребенка. 
    С её помощью можно работать над правильным  звукопроизношением, развивать фонематический слух,  совершенствовать лексическую сторону речи,  дикцию, голосовые, дыхательные функции.
Я принесла свой синтезатор, на котором дети с удовольствием играют, озвучивая придуманные истории, сказки, потешки, фантазии, образы.
Важно, что дети не пропевают, не  наигрывают  музыкальные фразы, показанные педагогом, а сочиняют,  импровизируют сами.
`,
    delayAnimation: "1000",
    photos: [
      {
        img: "teacherFind/music/1.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/music/2.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/music/3.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/music/4.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/music/5.jpg",

        delayAnimation: "",
      },
    ],
  },
  {
    compnayName: "Волшебная книжка",
    jobDescription: `Победив в конкурсе Л Выготского с проектом “Использование мягких развивающих книжек для развития коммуникативных способностей  у детей”, моя коллега ЕН Мамай подарила мне одну такую книгу, сразу ставшую для нас с детьми настольной.
Красочные аппликации,  симпатичные персонажи, интересные   ситуации,  заинтересовывают детей, делают игры увлекательными и продуктивными.
Благодаря  придуманным ситуациям, дети вступают в ролевое общение, учатся договариваться, дискутируют, создают свой замысел, самостоятельно расширяют пространство игры. А ещё, медвежонок из волшебной книжки ходит в гости к медвежатам Потаповым, и они к нему)`,
    delayAnimation: "800",
    photos: [
      {
        img: "teacherFind/book/1.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/book/2.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/book/3.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/book/4.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/book/5.jpg",

        delayAnimation: "",
      },
    ],
  },
  {
    compnayName: "Дружок-язычок",
    jobDescription: `Наш «Дружок-язычок» очень любит играть. 
    У него красивый домик, двор, где он любит гулять, качаться, прыгать, а ещё он угостит вас блинами, вареньем, покрасит потолок… 
    А что ещё он умеет, вам расскажут сами ребята, ведь они с ним подружились и вместе придумывают приключения и разные сказки.  
    У них так много друзей – и слоник, и лягушка, и мышка, и кот…`,
    delayAnimation: "",
    photos: [
      {
        img: "teacherFind/tongue/1.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/tongue/2.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/tongue/3.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/tongue/4.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/tongue/5.jpg",

        delayAnimation: "",
      },
    ],
  },
  {
    compnayName: "Добрый ветерок",
    jobDescription: `Речевое дыхание мы развиваем в блоке  «Добрый ветерок». 
    Игры на поддувание,  пузыри, дождик, снежинки, листики.. чего здесь только нет! 
    У каждого ребёнка свой индивидуальный пакетик с друзьями ветерка - ​волшебные соломинки,  шарики, трубочки. 
    Здесь тоже раздолье для интересных, придуманных детьми, историй.`,
    delayAnimation: "100",
    photos: [
      {
        img: "teacherFind/wind/1.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/wind/2.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/wind/3.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/wind/4.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/wind/5.jpg",

        delayAnimation: "",
      },
    ],
  },
  {
    compnayName: "Мои пальчики-друзья",
    jobDescription: `Блоком  «Мои пальчики-друзья» заведует рукодельница черепаха Тутти. 
    С ней вместе мы и шнуруем, и застёгиваем непослушные пуговки, и массажируем пальчики волнистыми палочками и колючими шариками.
     Ещё у неё есть чудо-бассейн для пальчиков, куда так весело нырять! 
     А сопровождаем мы всё эти стихами и потешками, многие из которых ребята придумали вместе с Тутти.`,
    delayAnimation: "200",
    photos: [
      {
        img: "teacherFind/fingers/1.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/fingers/2.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/fingers/3.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/fingers/4.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/fingers/5.jpg",

        delayAnimation: "",
      },
    ],
  },
  {
    compnayName: "Эмоции+я",
    jobDescription: `В необычном домике живут друзья-Эмоции. 
    Здесь мы учимся понимать эмоциональное состояние себя, другого, дети проговаривают фразу с разным эмоциональным окрасом, отгадывают, в какую квартиру мы сейчас пойдём. 
    Друзья-Эмоции учат детей обращать внимание на мимику, жесты, интонацию.
    Здесь дети придумывают свои истории про каждого из человечков, размышляют, почему один грустный, а другой – весёлый, вспоминают, когда испытывают такие эмоции сами`,
    delayAnimation: "300",
    photos: [
      {
        img: "teacherFind/emo/1.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/emo/2.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/emo/3.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/emo/4.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/emo/2.jpg",

        delayAnimation: "",
      },
    ],
  },
  {
    compnayName: "Расскажу-ка",
    jobDescription: `Блок «Расскажу-ка»  направлен на развитие лексико-грамматического строя и связной формы речи. Здесь много игр, направленных на развитие навыков словоизменения, словообразования...
    «Что из чего», «1-3-7»,  «Один – много», «Скажи наоборот», «Назови ласково», «Где спрятался»…всех не перечесть.
Очень важный уголок – «Расскажи, как было» - где дети составляют рассказы по картинкам
`,
    delayAnimation: "600",
    photos: [
      {
        img: "teacherFind/say/1.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/say/2.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/say/3.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/say/4.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/say/5.jpg",

        delayAnimation: "",
      },
    ],
  },
  {
    compnayName: "Театральная гостиная",
    jobDescription: `Театральная деятельность способствует формированию и развитию творческого мышления, через неё удовлетворяются многие интеллектуальные и эмоциональные потребности ребёнка. 
    Дети развиваются, раскрываются во время игры с персонажами, забывая об условностях, заявляют о себе, пробуют, на что способны, начинают верить в себя, перевоплощаются в своих героев. 
    Маленькие успехи складываются в большие победы.`,
    delayAnimation: "900",
    photos: [
      {
        img: "teacherFind/theater/1.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/theater/2.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/theater/3.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/theater/4.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/theater/5.jpg",

        delayAnimation: "",
      },
    ],
  },
  {
    compnayName: "Песочный мастер",
    jobDescription: `Песок – это универсальное средство для творчества и терапии, которое оказывает развивающее действие на детей. Игра с песком – одно из самых любимых занятий детей, это много новых эмоций и развивающих игр. Если  придать тяге ребёнка к песку  творческую составляющую – получается волшебство, происходит развитие мелкой моторики рук и снятие психоэмоционального напряжения.
Несколько движений пальцами – и вот перед нами домик, кот, лодка, рыбка…
А вот и буквы появляются...`,
    delayAnimation: "1100",
    photos: [
      {
        img: "teacherFind/sand/1.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/sand/2.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/sand/3.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/sand/4.jpg",

        delayAnimation: "",
      },
      {
        img: "teacherFind/sand/5.jpg",

        delayAnimation: "",
      },
    ],
  },
];

const Resume = () => {
  return (
    <>
      <section id="resume" className="section">
        <div className="container">
          <div className="title">
            <div>
              <h4>Кейс-технология. В чем ценность?</h4>
            </div>
          </div>

          <Services/>
          <div className="title">
            <h3>Педагогическая находка</h3>
          </div>
          <div className={'m-15px-tb'}>
            <h3>КейсГрад</h3>
          </div>
          <div className="resume-box">
            <div
              className="resume-row"
              data-aos="fade-up"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <div className="row">
                <div className="col-12">
                  <div className="rb-top">
                    <h6>КейсГрад</h6>
                    <p> Продумывая развивающую предметно-пространственную среду кабинета, которая, по ФГОС, должна быть
                      насыщенной, трансформируемой, содержательной, полифункциональной, вариативной, доступной и
                      безопасной,
                      я создала КейсГрад – город интересных кейс-приключений, в котором можно эффективно решать
                      коррекционные задачи в психологически комфортных условиях.
                    </p>
                    <p>
                      При использовании кейс-технологии происходит краткосрочное обучение на основе реальных или
                      вымышленных ситуаций, направленное не столько на освоение знаний, сколько на формирование у
                      воспитанников новых коммуникативных компетенций. Ценно, что те проблемы, с которыми ребёнок может
                      столкнуться в жизни или уже сталкивался, можно проиллюстрировать с помощью ситуации, которая
                      должна заинтересовать ребёнка, быть ему по силам, не содержать подсказок относительно решения
                      проблемы, в ней должно содержаться достаточно информации
                    </p>
                    <p className="d-flex justify-content-center">
                      <img className="w-50" src={'img/teacherFind/01.jpg'}></img>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* End title */}
          <div className="resume-box">
            {resumeContent.map((val, i) => (
              <div
                className="resume-row"
                key={i}
                data-aos="fade-up"
                data-aos-duration="1200"
                data-aos-delay={val.delayAnimation}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="rb-right">
                      <h6>{val.compnayName}</h6>
                      <p style={{ whiteSpace: "pre-line" }}>{val.jobDescription}</p>
                    </div>
                  </div>
                  <div className="col-12">
                    <Slider {...settings}>
                      {val.photos.map((val, j) => (
                        <div
                          key={j * 100}
                          data-aos="fade-up"
                          data-aos-duration="1200"
                          data-aos-delay={val.delayAnimation}
                        >
                          <div className="media">
                            <img className="slick-img"
                              src={`img/${val.img}`}
                              alt="review"
                            ></img>
                          </div>
                        </div>
                      ))}
                    </Slider>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/*/!* separated *!/*/}
          {/*<div*/}
          {/*  className="separated"*/}
          {/*  style={{*/}
          {/*    backgroundImage: `url(${*/}
          {/*      process.env.PUBLIC_URL + "img/border-dark.jpg"*/}
          {/*    })`,*/}
          {/*  }}*/}
          {/*></div>*/}
          {/*/!* End separated *!/*/}

          {/*<div className="title">*/}
          {/*  <h3>Education & Skills</h3>{" "}*/}
          {/*</div>*/}

          {/*<div className="row align-items-center">*/}
          {/*  <div*/}
          {/*    className="col-lg-4 m-15px-tb"*/}
          {/*    data-aos="fade-up"*/}
          {/*    data-aos-duration="1200"*/}
          {/*  >*/}
          {/*    <ul className="aducation-box">*/}
          {/*      {educatonContent.map((val, i) => (*/}
          {/*        <li key={i}>*/}
          {/*          <span>{val.passingYear}</span>*/}
          {/*          <h6>{val.degreeTitle} </h6>*/}
          {/*          <p>{val.instituteName}</p>{" "}*/}
          {/*        </li>*/}
          {/*      ))}*/}
          {/*    </ul>*/}
          {/*  </div>*/}
          {/*  /!* End .col *!/*/}

          {/*  <div*/}
          {/*    className="col-lg-7 ml-auto m-15px-tb"*/}
          {/*    data-aos="fade-up"*/}
          {/*    data-aos-duration="1200"*/}
          {/*    data-aos-delay="200"*/}
          {/*  >*/}
          {/*    <Skills/>*/}
          {/*  </div>*/}
          {/*  /!* End .col *!/*/}
          {/*</div>*/}
        </div>
      </section>
    </>
  );
};

export default Resume;
