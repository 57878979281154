import React from "react";

const ServiceContent = [
  {
    icon: "icon-circle-compass",
    title: "Самостоятельность, инициативность",
    descriptions: `Ценно, что те проблемы, с которыми ребёнок может столкнуться в жизни или уже сталкивался, можно проиллюстрировать с помощью ситуации, которая  должна заинтересовать ребёнка, быть ему по силам, не содержать подсказок относительно решения проблемы, в ней должно содержаться достаточно информации`,
    delayAnimation: "",
  },
  {
    icon: "icon-happy",
    title: "Коммуникативные компетенции",
    descriptions: `Происходит краткосрочное обучение на основе реальных или вымышленных ситуаций, направленное не столько на освоение знаний, сколько на формирование у воспитанников новых коммуникативных компетенций
Важно, что в процессе деятельности дети включены в активный поиск, ставят перед собой вопрос «что я знаю?» По данной проблеме формируется представление, что ребёнок не знает и хочет узнать.
 Дети актуализируют и обобщают имеющиеся знания по данной проблеме, задают вопросы, на которые хотели бы получить ответ`,
    delayAnimation: "",
  },
  {
    icon: "icon-lightbulb",
    title: "Проблематика и решение",
    descriptions: `Дети изучают ситуацию, разбираются в проблеме,  затем предлагают возможные пути решения, и самостоятельно или совместно со взрослым, выбирают самый оптимальный путь выхода из проблемы.
Очень ценен сам процесс обсуждения, дискуссия, когда в рассуждении рождается решение.  
Дети через проблемные вопросы, через обсуждения, взаимодействие друг с другом начинают решать кейсовые задачи.`,
    delayAnimation: "200",
  },
  {
    icon: "icon-key",
    title: "Вопросы",
    descriptions: `Очень важно правильно сформулировать вопрос, содержащий  задание и мотивацию на решение проблемы. 
Важно педагогу в процессе обсуждения кейса воздерживаться от ответов на вопросы, а давать слово детям, чтобы они сами отвечали на них, также, не менее важно, анализировать ситуацию через вопросы: «Как ты думаешь, в чём здесь проблема?», «Что бы мы могли сделать?», «Как думаешь, что может произойти, если…?»`,
    delayAnimation: "400",
  },
  {
    icon: "icon-hotairballoon",
    title: "Результаты. Дети:",
    descriptions: `- Успешно сотрудничают, работают в паре, в группе. 
- Успешно договариваются друг с другом.
 - Действуют без пошагового контроля. 
- Создают свой собственный замысел. 
- Осваивают монологическую, диалогическую речь, лексико-грамматические средства языка.
 - Демонстрируют различные позиции и точки зрения.
-  Сами пытаются придумать продолжение истории, говорят: "А ещё же можно двор украсить, в саду фрукты, в огороде овощи..." `,
    delayAnimation: "800",
  },
  {
    icon: "icon-picture",
    title: "Результаты. Родители:",
    descriptions: `Совместно с детьми  изготавливают мебель, атрибуты, играют дома в дизайнеров. Такая деятельность способствует сплочению семьи, повышению активности, заинтересованности родителей к занятиям с ребёнком; наблюдается  интерес и рост посещаемости совместных мероприятий с детьми в ДОУ.`,
    delayAnimation: "800",
  },
  {
    icon: "icon-profile-female",
    title: "Позиция педагога",
    descriptions: `Важно, что педагог занимает позицию равного партнёра, совместно проживает с детьми события, задаёт вопросы и побуждает детей к высказыванию своего мнения, формирует аналитические способности детей путём обобщения материала. Дети применяют полученные знания, переводя их в реальность. 
При обсуждении принимаются все версии, идеи не критикуются, но разногласия фиксируются. 
Педагог выступает в качестве проводника, подталкивая дошкольников к размышлению, внимательно выслушивает их ответы.`,
    delayAnimation: "600",
  },
];
export default function Service() {
  return (
    <>
      <div className="row">
        {ServiceContent.map((val, i) => (
          <div
            className="col-md-6 col-lg-6 my-3"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="feature-box-01">
              <div className="icon">
                <i className={`icon ${val.icon}`}></i>
              </div>
              <div className="feature-content">
                <h5>{val.title}</h5>
                <p style={{ whiteSpace: "pre-line" }}>{val.descriptions}</p>
              </div>
            </div>
            {/* End .feature-box-01 */}
          </div>
        ))}
      </div>
    </>
  );
}
