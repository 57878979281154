import React from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import Masonry from "react-masonry-css";

import YouTube from 'react-lazyload-youtube';

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
};

const opts = {
  width: '500',
  height: '281',
  playerVars: {},
};

const tabList = [
  { title: "Фестиваль игры 4D", href: "hHmPtx5wj5s" },
  { title: 'Игра "Цветик-семицветик"', href: "iKL3_XQTtbs" },
  {
    title: "Всё дела...",
    href: "vU1tjapefVM"
  },
  { title: "Колыбельная для шалуньи", href: "unqruMzxWrU" },
  {
    title: "Подарок",
    href: "s3YT0oNfr4I"
  },
  {
    title: 'Конкурс от "КнигаБум"',
    href: "mjzyQ90H_h0"
  },
  { title: "Тропинка ко сну", href: "sce65-omrko" },
  {
    title: "Колыбельная",
    href: "OH6CqNys4So"
  },
  { title: "Поделка", href: "_lETXQzfZsE" },
  { title: "Полетели", href: "XMr79MBxTkE" },
  { title: "Я рисую человека", href: "KgCuYNG5n-A" },
  { title: "Ниточка", href: "D5w_19KOb7Y" },
  { title: "Ленивый пластилин", href: "c_g6NkFFn2A" },
  { title: "Щекотка", href: "7F5FkeqTInA" },

];

const _onReady = (event) => {
  // access to player in all event handlers via event.target
  event.target.pauseVideo();
};

const HappyDays = () => {
  return (
    <SimpleReactLightbox>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        columnClassName="my-masonry-grid_column"
      >
        {tabList.map((val, i) => (
          <div className="feature-box-02 d-flex align-items-center">
            <div className="media-body">
              <h6>{val.title}</h6>
              <YouTube
                videoId={val.href} opts={opts} onReady={_onReady}
              />
            </div>
          </div>
        ))}
      </Masonry>
    </SimpleReactLightbox>
  );
};

export default HappyDays;
