import React, { useState } from "react";
import Modal from "react-modal";
import Contact from "../contact/Contact";

Modal.setAppElement("#root");

const News = () => {
  const author = 'Алла Зинарева';

  function toggleModal0() {
    setIsOpen0(!isOpen0);
  }

  function toggleModal1() {
    setIsOpen1(!isOpen1);
  }

  function toggleModal2() {
    setIsOpen2(!isOpen2);
  }

  function toggleModal3() {
    setIsOpen3(!isOpen3);
  }

  function toggleModal4() {
    setIsOpen4(!isOpen4);
  }

  function toggleModal5() {
    setIsOpen5(!isOpen5);
  }

  function toggleModal6() {
    setIsOpen6(!isOpen6);
  }

  function toggleModal7() {
    setIsOpen7(!isOpen7);
  }

  function toggleModal8() {
    setIsOpen8(!isOpen8);
  }

  const [isOpen0, setIsOpen0] = useState(false);
  const [isOpen1, setIsOpen1] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isOpen3, setIsOpen3] = useState(false);
  const [isOpen4, setIsOpen4] = useState(false);
  const [isOpen5, setIsOpen5] = useState(false);
  const [isOpen6, setIsOpen6] = useState(false);
  const [isOpen7, setIsOpen7] = useState(false);
  const [isOpen8, setIsOpen8] = useState(false);

  const articleAddons = [
    {
      toggleModal: toggleModal0,
      setIsOpen: setIsOpen0,
      isOpen: isOpen0,
      img: 'img/blog/campus.png',
      date: '17 Января 2022',
      header: 'Кампус Красноярского края от Университета Детства.',
      content: `                      
        <p>
          Университет Детства (ссылка на сайт: https://universityofchildhood.ru) объединяет увлеченных
          педагогов,
          способных искать, находить и применять лучшие педагогические практики, ориентированные на ребенка,
          даёт возможность повышать свой авторитет, профессионализм, свою удовлетворенность в профессии.
        </p>
        <p>
          Я являюсь региональным лидером и организатором Кампуса Красноярского края сообщества «Университет
          детства» в 2020-2022 г.
          В нашем сообществе единомышленников, людей, ищущих и развивающихся, смелых и творческих, каждый
          участник чувствует свой опыт востребованным, находит поддержку, чтобы полнее раскрыть и проявить
          себя
          — именно благодаря таким людям и происходят позитивные изменения в дошкольной среде.
        </p>
        <p>
          Ссылки на страницу кампуса:
          https://vk.com/club201274705
        </p>
        <p>
          Приглашаем Вас в нашу рубрику #ЗвездаКампуса, где мы знакомимся с нашими самыми яркими педагогами
          Красноярского края - победителями Международного конкурса им. Л.С. Выготского
        </p>
        <p>
          Приглашаем Вас в рубрику #СобытияККК (Кампуса Красноярского края)
        </p>
        <p>
          Приглашаем Вас в рубрику #КАПШУБЕН
        </p>
        <p>
          КАПы (Клубы активных педагогов) дают возможность педагогам сформировать сообщество
          единомышленников,
          повысить профессиональные и лидерские компетенции, презентовать проекты, направленные на развитие
          дошкольного образования, ориентированного на ребёнка, познакомиться с индивидуальным
          профессиональным
          опытом участников, поделиться своим опытом.
          В Красноярском крае организован Клуб активных педагогов КАП ШУБЕН (Шарыповского, Ужурского,
          Балахтинского, Емельяновского, Новосёловского районов), объединивший представителя от каждого ДОУ.
          Вместе мы меняем дошкольное образование к лучшему!
        </p>
`,
      links: `
        <li href="/#">#КАПШУБЕН</li>
        <li href="/#">#СобытияККК</li>
        <li href="/#">#ЗвездаКампуса</li>
        <li href="https://universityofchildhood.ru" target="_blank" rel="noreferrer">Университет Детства
        </li>
      `
    },
    {
      toggleModal: toggleModal1,
      setIsOpen: setIsOpen1,
      isOpen: isOpen1,
      img: 'img/blog/case.png',
      date: '17 Января 2022',
      header: 'Кейс-технология',
      content: `                      
        <p>
Тип практики: образовательная практика.

 

Направление практики: ОВЗ, инклюзия на уровне образовательного учреждения.

 

Название практики: Кейс-технология как инновационная образовательная модель, направленная на приобретение детьми с тяжёлыми речевыми нарушениями новых коммуникативных компетенций.



Актуальность:

Размышляя, как  менять деятельность дошкольника, привыкшего к получению готовых знаний, подчинению, послушанию, монотонной работе на занятии, я задала себе вопрос: «А смогут ли дети применить знания, которые мы с ними осваиваем, в свободной деятельности?»

Изучая литературу, инновационный опыт работы педагогов, я обратила внимание на  кейс-технологию, противопоставленную таким видам работы, как повторение за педагогом, ответы на вопросы, пересказ готового текста.

 

Я работаю в логопедической группе с детьми, испытывающими трудности в освоении новых умений, навыков, а также  имеющими бедный словарный запас, практически не сформированный лексико-грамматический строй, слабую мотивацию к деятельности. В настоящее время возникло реальное противоречие между объективным ростом требований к развитию воспитанников в момент поступления в школу, и недостаточностью эффективных технологий логопедической работы.

 

Цель практики:

Использование кейс-технологии как инновационной образовательной модели, направленной на приобретение детьми с тяжёлыми речевыми нарушениями новых компетенций.

 

Задачи:

-Разработать практические материалы к кейс-технологии.

-Организовать практическую работу с детьми на основе кейсов.

-Научить воспитателей использовать кейс-технологию в  деятельности.

-Простроить взаимодействие с родителями, используя подготовленный материал.

 

Основная идея практики

 

Гипотеза:

 При использовании кейс-технологии  происходит  краткосрочное обучение на основе реальных или вымышленных ситуаций, направленное не столько на освоение знаний, сколько на формирование у воспитанников новых коммуникативных компетенций.

 

Мне показалось важным, что те проблемы, с которыми ребёнок может столкнуться в жизни или уже сталкивался, можно проиллюстрировать с помощью ситуации, которая  должна заинтересовать ребёнка, быть ему по силам, не содержать подсказок относительно решения проблемы, в ней должно содержаться достаточно информации.  Очень важно правильно сформулировать вопрос, содержащий  задание и мотивацию на решение проблемы. Дети изучают ситуацию, разбираются в проблеме,  затем предлагают возможные пути решения и совместно со взрослым выбирают самый оптимальный путь выхода из проблемы.

 

Для реализации практики необходимо подготовить кейсы:

- -описание конкретной ситуации;

- дидактические материалы;

- задания к кейсу.

 

Размышляя, какой именно кейс выбрать, просматривая варианты печатных кейсов  (иллюстрации, фото, картины), видео кейсов (фильм, аудио и видео материалы), я решила создать новый предметно-игровой кейс из подручных материалов. Для комнат медведей с помощью дырокола и тесёмки скрепила по три доски для лепки, обои, ковры сшила из ткани, фигурки медведей и мебель для комнат распечатала на цветном принтере, обклеила картоном и скотчем (приложение 1)

 

Важно, что в процессе деятельности дети включены в активный поиск,  ставят перед собой вопрос «что я знаю?»  По данной проблеме формируется представление, чего же он не знает и хочет узнать. При обсуждении принимаются все версии, идеи не критикуются, но разногласия фиксируются. Педагог выступает в качестве проводника, подталкивая дошкольников к размышлению, внимательно выслушивает их ответы. Дети актуализируют и обобщают имеющие сознания по данной проблеме, задают вопросы, на которые хотели бы получить ответ.

 

Результативность практики прослеживается в положительной динамике формирования коммуникативной компетентности и развития творческой инициативы воспитанников. В индивидуальных картах (приложение 2) на  основании результатов обследования в процессе наблюдения  видно, что у каждого ребёнка "сегодняшнего" есть изменения относительно самого себя "вчерашнего".             

Дети начинают соотносить свои устремления с интересами других; продуктивно взаимодействовать друг с другом, решая общую задачу; получать необходимую информацию в общении, выслушивать другого и приходить к общему решению; отстаивать свою точку зрения, адекватно реагировать в конфликтных ситуациях, принимать помощь.

 

Результат по проекту:

Для детей:

- Успешно сотрудничают, работают в паре, в группе.

- Успешно договариваются друг с другом.

- Действуют без пошагового контроля.

- Создают свой собственный замысел.

- Осваивают  монологическую, диалогическую   речь, лексико-грамматические  средства  языка.

- Демонстрируют различные позиции и точки зрения.

Для педагогов:

-  Осуществляют инновационную деятельность  по использованию кейс-технологии.

- Развивают свою профессиональную компетентность.

- Создают благоприятную  предметно-развивающую среду и дополнительное образовательное пространство для  творческого  и речевого  развития   детей.

Для родителей:

Повышение их активности, заинтересованности; рост посещаемости  совместных мероприятий с детьми.

 

Один из неожиданных результатов, помимо запланированных, то, что дети сами пытаются придумать продолжение истории, говорят: "А ещё же можно двор украсить, в саду фрукты, в огороде овощи..." И такая мысль (о продолжении) пришла в голову многим детям. Они спрашивают: "А можно мы дома с мамой нарисуем ещё такую одежду? А я с папой машинку Мишутке сделаю", "Интересно, какое блюдо понравится сестрёнке?" и тп. Мне кажется, что заинтересованность, инициативность, активность детей - лучший показатель успешности практики.

 

Индивидуальная карта мониторинга формирования коммуникативной компетентности
        </p>
`,
      links: `
        <li href="/#">#КейсТехнология</li>
        <li href="/#">ОВЗ</li>
        <li href="/#">Образовательная практика</li>
      `
    },
    {
      toggleModal: toggleModal2,
      setIsOpen: setIsOpen2,
      isOpen: isOpen2,
      img: 'img/blog/rechetsvetik.png',
      date: '17 Января 2022',
      header: 'Приёмы обучения фонематическим технологиям программы Галины Ванюхиной «Речецветик»',
      content: `                      
        <p>
Мы знаем, что звуки только слышатся и произносятся. А детям почему-то очень хочется их увидеть. Что ж, пусть смотрят. Но они должны знать, что эти звуковые человечки живут лишь в речевой стране Говорунии. Давайте познакомимся с ними и с их фонетическим миром.


-   Шесть гласных звуков [у, а, и, э, о, ы] - шесть девочек. Полуоткрытый рот у фигурок-символов говорит о свободном выдохе воздуха: гласные легко поются. Красный цвет платья-квадратика указывает на главную роль в словообразовании, колокольчики - на полногласие звуков, губы - на схематичную артикуляцию.

     
Согласные звуки - мальчики. Эта мужская половина слов участвует в их строительстве и преобразовании на фундаменте гласных звуков. Сжатые губы - символ преград на пути выдыхаемого воздуха. Песенок у согласных не получается: они свистят, шипят, вибрируют, взрываются, их можно потянуть [ж - ж - ж], но не пропеть! Артикуляция у согласных не изображена. Поэтому при знакомстве с каждым новым согласным звуком дети должны уточнить положение губ и языка: подражая взрослому, глядя в зеркало (самоконтроль) и друг на друга (взаимоконтроль).
Звонкие согласные произносятся голосом и с шумом. Об этом напоминают колокольчики на их шапочках. Глухие согласные издают только шум, колокольчиков у них нет. Отличить звонкий от глухого можно, прикладывая руки к горлу: оно гудит или молчит.
Твердые согласные носят синие костюмы - квадраты. Характер у них строгий и рациональный. Они шипят строго [ш - ш - ш], стучат строго [т - т - тъ], рычат строго [р - р - р], но произносятся легко, без напряжения. Мягкие согласные одеты в зеленые костюмчики. Характер у них мягкий, сердечный. Они шипят нежно [щ - щ - щ], стучат нежно [ть - ть - ть] и даже рычат нежно [рь - рь - рь]. Это требует много сил. Вот почему произносятся они с напряжением.




-  Характеристика звуков осуществляется по вопросам звуковой анкеты, по своему содержанию напоминающей анкетирование человека. Фамилия звука зависит от его принадлежности к семье гласных или согласных. Имя передает непосредственное звучание. Отчество у звонких и глухих согласных определяется по наличию отца - Голоса. Характер бывает твердый или мягкий.
Сведения о новом звуке можно закрепить с помощью внешнего вида звукового человечка, по конфигурации губ, наличию колокольчика и цвету костюмчика.
Опорой для планомерной характеристики любых звуков (гласный или согласный, звонкий или глухой, твердый или мягкий) служит опосредованная схема с атрибутами: приоткрытый рот - сомкнутые губы, колокольчик - перечеркнутый колокольчик, синий - зеленый квадратик.




-  Для выделения звука из заданного положения в слове нужно подключать дополнительные сенсорные сигналы.
• При определении последнего звука в слове взрывной согласный можно «бросить» (сказать) на ладошку, слегка отделив его от предыдущих звуков. Соединяя след от воздушной струи с утрированным произношением звука, ребенок легко называет его.
• При подражании взрослому одновременно с произнесением взрывного согласного в любом положении в слове «поймать» его резким движением руки в кулачок, словно комара схватить.
• Гласные и щелевые согласные нужно потянуть, предварительно обратив внимание детей на самый длинный звук в слове. Во время его произношения предложить «поскользить» с ним словно по веревочке рукой: НОС__ОК, НОС__.
• Для нахождения слов с заданным звуком среди других слов можно «застукать» их: хлопнуть ладошкой, если услышишь заданный звук в слове (по принципу игры в «Баши»).

-  Особенное затруднение дети испытывают при определении первого звука в слове, когда это согласный в слиянии с гласным: ДОМ - [Д], а не [ДО]. К произношению отдельного звука нужно подходить постепенно.
• Выделять начальный взрывной согласный вне слияния, утрируя его произношение: К - ТО?
• Выделять начальный гласный вне слияния: А__ИСТ.
• Выделять начальный щелевой согласный при слиянии с гласным: С__УП.
• Выделить начальный смычно-щелевой согласный при слиянии с гласным, утрируя его произношение и слегка протягивая его: В__АТА.
• Выделять начальный взрывной согласный при слиянии с гласным, утрируя его произношение: К__ОТ.
При этом в момент произношения выделяемого звука можно подключить перечисленные ранее опорные сигналы.
- Знакомству со слогами предшествует ориентация в пространственной структуре слова. Дети учатся определять местоположение заданного звука в начале, середине и конце слов. Делать это можно с помощью:
- матрицы, состоящей из трех ячеек; при нахождении местоположения звука первая, средняя или последняя ячейка закрывается квадратной фишкой - синим или зеленым костюмчиком заданного звука или самим звуковым человечком;
- сюжетных символов. Например, при словарной теме «Осень» нужная ячейка за-крывается желтым листиком. При знакомстве с фруктами местоположение звука [и] отмечается соответствующей картинкой;
- разрезной трехчастной картинки звуковой птички, клюв которой - первый звук в слове, хвостик - последний, крылышки и лапки - все звуки из середины слова (по мотивам рассказов Бианки, в которых синичка Зинька по аналогии с собой определяла носик, серединку и хвостик года);




- слово-домик, к началу, середине или концу которого можно прикладывать звукового человечка или фишку - квадратик;
- определение первых и последних звуков в их названии: «Найти носы» и «Вернуть хвосты» различным предметам.
-  Для определения местоположения звука в слоговой структуре слова используется символ слова-домика со слогами-подъездами.



 


• Уточнив порядковое место слога с заданным звуком, нужно закрыть фишкой или закрасить соответствующий квадратик - подъезд или «поселить» туда звукового человечка.
• Подбирая слово с заданным звуком в определенном слоге, желательно опираться на схематичное изображение. Например, двухподъездный домик, первый квадратик которого отмечен синей фишкой звука [л], подсказывает такие слова как ЛЫ-ЖИ, ПАЛ-КИ…


-  Там, где рядом находятся два звуковых человечка и более, нужно определять их порядок или сливать их в слово, например:
- в слове МАК первый звук - [м], второй - [а], третий - [к];
- в слове МЕЛ первый звук - [м’], второй - [э], третий - [л].
        </p>
`,
      links: `
        <li href="/#">#Речецветик</li>
        <li href="/#">Галина Ванюхина</li>
        <li href="/#">Образовательная практика</li>
      `
    },
    {
      toggleModal: toggleModal3,
      setIsOpen: setIsOpen3,
      isOpen: isOpen3,
      img: 'img/blog/logoped.png',
      date: '17 Января 2022',
      header: 'Когда ребёнку необходим логопед',
      content: `                      
        <p>
Ребёнок ограничен в общении со взрослыми.
Ребёнок раннего возраста отстаёт в речевом развитии по непонятным причинам.
Ребёнок имеет нарушения зрения или слуха.
Укороченная  подъязычная связка (уздечка).
Ребенок в 2 года не говорит, использует только лепетные слова.
Ребенок в 2,5 произносит отдельные слова, нет фразы.
Речь ребенка в 3 года неразборчива и малопонятна для окружающих.
Пользуется упрощенными словами, меняет слоги в слогах.
У ребенка неправильное произношение многих звуков, отчего речь неразборчива.
Ребенок уже занимался с логопедом, но и после 5 лет некоторые звуки не произносит.
Ребенок не умеет «связно рассказывать» о событиях своей жизни, у него маленький словарный запас.
Не усвоены грамматические нормы языка. Ребенок неправильно согласует слова.
Ребенок испытывает трудности в обучении чтению.
Ребенок при письме допускает много «глупых» ошибок (пропуски, замены, недописывание букв, слогов и даже слов!), хотя правила знает.
У ребенка проблемы с чтением: читает медленно, с ошибками, часто придумывает конец слова или предложения.
Сохранилось неправильное звукопроизношение (картавость, шепелявость и др.)
Стойкая неуспеваемость по русскому языку. В этом случае необходимо логопедическое обследование, чтобы выяснить, нет ли у ребенка дисграфии, дислексии или дизорфографии.
        </p>
`,
      links: `
        <li href="/#">#Речецветик</li>
        <li href="/#">Галина Ванюхина</li>
        <li href="/#">Образовательная практика</li>
      `
    },
    {
      toggleModal: toggleModal4,
      setIsOpen: setIsOpen4,
      isOpen: isOpen4,
      img: 'img/blog/palm.png',
      date: '17 Января 2022',
      header: 'Пальчиковая гимнастика',
      content: `                      
        <p>
 
           Движения рук и пальцев, сопровождаемые короткими стихами, благотворно действуют на развитие детей. 
Сотрудниками Института физиологии детей АПН РФ доказано, что тонкие движения пальцев рук положительно влияют на мышление и развитие детской речи.

Почему нужно развивать пальчики?

- Выполнение упражнений и ритмических движений пальцами приводит к возбуждению в речевых центрах мозга и резкому усилению согласованной деятельности речевых зон, что стимулирует развитие речи;
- Игры с пальчиками создают благоприятный эмоциональный фон, развивают умение подражать взрослому, повышают речевую активность ребенка;
- Ребенок учится концентрировать свое внимание и правильно его распределять 
- При выполнении упражнений с сопровождением их стихотворными строчками речь становится более четкой, ритмичной, яркой, усиливается контроль за выполняемыми движениями.
- Развивается память ребенка, так как он учится запоминать определенные положения рук и последовательность движений.
- Развивается воображение и фантазия ребенка. Овладев упражнениями, он сможет "рассказывать руками" целые истории.
- В результате освоения всех упражнений кисти рук и пальцы приобретут силу, подвижность и гибкость, а это в дальнейшем облегчит освоение навыков письма.
 
1. Упражнения для кистей рук:
- Развивают подражательную способность, достаточно просты и не требуют тонких дифференцированных движений;
- Учат напрягать и расслаблять мышцы;
- Развивают умение сохранять положение пальцев некоторое время;
- Учат переключаться с одного движения на другое.


2. Упражнения для пальцев условно-статические и динамические:
- Совершенствуют полученные ранее навыки на более высоком уровне и требуют более точных движений.
- развивают точную координацию движений;
- Учат сгибать и разгибать пальцы рук;
- Учат противопоставлять большой палец остальным.


               Специалисты советуют начинать занятия пальчиковой гимнастикой с 6-7 месяцев. Но и в более позднем возрасте занятия будут очень полезны и эффективны
Начинать нужно с ежедневного массажа по 2-3 минуты кистей рук и пальцев;
- Поглаживать и растирать ладошки вверх-вниз;
- Разминать и растирать каждый палец вдоль, затем - поперек;
- Растирать пальцы спиралевидными движениями;
Затем можно выполнять некоторые упражнения третьей группы в пассивной форме, то есть Вы сами сгибаете и разгибаете пальцы ребенка и совершаете другие ритмичные движения, сопровождая их ритмичными строчками;
Примерно с 10 месяцев кроме пассивных упражнений второй и третьей групп ребенка нужно учить:
- катать между ладонями и пальцами шарики и палочки разного размера;
- рвать бумагу, отщипывать кусочки от куска глины или пластилина;
- перекладывать из одной коробки в другую разные мелкие предметы (бусинки, пуговицы) одной рукой или двумя одновременно;
Помните!
Любые упражнения будут эффективны только при регулярных занятиях. Занимайтесь ежедневно около 5 минут.
 
Правила выполнения упражнений:

- Сначала все упражнения выполняются медленно. Следите, чтобы ребёнок правильно воспроизводил и удерживал положение кисти или пальцев и правильно переключался с одного движения на другое. При необходимости помогите малышу или научите его помогать себе второй рукой.
- Упражнения отрабатываются сначала одной рукой (если не предусмотрено участие обеих рук), затем — другой рукой, после этого — двумя одновременно.
Когда все упражнения будут хорошо знакомы, можно выполнять следующие игровые задания:
- Запоминать и повторять серию движений по словесной инструкции, начиная с двух движений и заканчивая тремя, четырьмя и больше.
- «Рассказывать руками» сказки и маленькие истории. Сначала Вы сами придумываете рассказ, затем предлагаете сочинить свою историю малышу.
Например: «В одном чудесном месте протекала речка (изображаем «речку»). В ней жила маленькая рыбка (изображаем «рыбку»). Как-то раз по реке проплыл пароход (упражнение «пароход»), он сильно гудел и рыбка испугалась и уплыла. А на берегу реки («река»), росло дерево (упражнение «дерево») и т. д.
 
Упражнения

 

Ветер дует нам в лицо (руками машем себе в лицо)
Закачалось деревцо (руки вверх и качаемся)
Ветерок все тише, тише (медленно приседаем)
Деревцо все выше, выше (медленно встаем, поднимаемся на носочки, руки вверх)
Как на горке снег, снег (встаем на носочки, руки вверх)
И под горкой (приседаем)
И на елке (встаем, руки в стороны)
И под елкой (обхватываем себя руками)
А под снегом спит медведь (пальчик к губам)
Тише, тише, не шуметь (шепотом, поворачиваясь в разные стороны)

“Котятки”
Все котятки мыли лапки:
Вот так! Вот так! (изображаем мытье рук)
Мыли ушки, мыли брюшки:
Вот так! Вот так!
А потом они устали:
Вот так! Вот так!
Сладко-сладко засыпали:
Вот так! Вот так! (имитируем все указанные в стихотворении движения)

“Птички и ветер”
Маленькие птички,
Птички-невелички,
По лесу летают,
Песни распевают. (машем руками, как крылышками)
Буйный ветер налетел, (руки вверх, раскачиваемся из стороны в сторону)
Птичек унести хотел.
Птички спрятались в дупло (приседаем на корточки, закрываем голову руками)
Там не тронет их никто.
“Гроза”
Закапали капли (постучать двумя пальцами каждой руки по столу)
Идет дождь (постучать четырьмя пальцами)
Он льет как из ведра! (стучим сильнее)
Пошел град (косточками пальцев выбиваем дробь)
Сверкает молния (шипящий звук, рисуем молнию пальцем в воздухе)
Гремит гром! (барабаним кулаками или хлопаем в ладошки)
Все быстро убегают домой (прячем руки за спину)
А утром снова ярко светит солнце! (описываем руками большой круг)

“О левой и правой руке”
Смотрите-ка, вот две руки:
Правая и левая! (вытягиваем руки вперед, показывая)
Они в ладоши могут бить –
И правая, и левая! (хлопаем в ладоши)
Они мне могут нос зажать –
И правая, и левая! (по очереди зажимаем нос правой и левой рукой)
Ладошкой могут рот прикрыть -
И правая, и левая! (прикрываем рот той и другой)
Дорогу могут показать –
И правая, и левая! (показываем направление то правой, то левой рукой)
В носу умеют ковырять –
И правая, и левая! (хм. ковыряем в носу по очереди каждой рукой)
А если кто-то завопит,
И правая, и левая
Помогут уши мне заткнуть! (затыкаем уши)
С руками я всегда дружу –
И с правою, и с левою! (дружеское рукопожатие)
И могут ласковыми быть
И правая, и левая!
Обнимут вас, ко мне прижмут –
И правая, и левая! (обхватываем себя руками – “обнимаем”)

“Мышиная семья”
Это папа-мышь. (показываем большой палец)
Он красивый, как все мышки:
У него мягкая шкурка (гладим одной рукой кисть другой руки)
У него большие уши (рисуем пальцем в воздухе уши)
У него острый носок (складываем кончики пальцев вместе и приставляем к носу)
А хвост – во-о-о-о-от такой! (показываем руками отрезок ок.30 сантиметров)
Это мама-мышь. (показываем указательный палец)
Она красивая, как все мышки:
У нее мягкая шкурка (гладим одной рукой кисть другой руки)
У нее хорошенькие ушки (рисуем пальцем в воздухе уши)
У нее острый носок (складываем кончики пальцев вместе и приставляем к носу)
А хвост – во-о-о-от такой! (показываем руками отрезок ок.20 сантиметров)
Это брат-мышь. (показываем средний палец)
Он красивый, как все мышки:
У него мягкая шкурка (гладим одной рукой кисть другой руки)
У него чумазые уши (рисуем пальцем в воздухе уши)
И острый нос (складываем кончики пальцев вместе и приставляем к носу)
А хвост – во-о-от такой! (показываем руками отрезок ок.15 сантиметров)
Это сестра-мышь. (показываем безымянный палец)
Она красивая, как все мышки:
У нее шелковистая шкурка (гладим одной рукой кисть другой руки)
В ушах сережки (рисуем пальцем в воздухе уши)
У нее острый носок (складываем кончики пальцев вместе и приставляем к носу)
А хвост – во-о-от такой! (показываем руками отрезок ок.10 сантиметров)
А это мышка-малышка. (показываем мизинец)
Она совсем не похожа на других мышей!
Шерстка у нее гладкая (гладим одной рукой кисть другой руки)
Ушки маленькие (рисуем пальцем в воздухе уши)
Носик остренький (складываем кончики пальцев вместе и приставляем к носу)
А хвост – во-от такой! (показываем руками отрезок ок.5 сантиметров)

“Кошка и горшок с молоком”
В кухне нашей под столом
Стоит крынка с молоком. (складываем из большого и всех остальных пальцев левой руки кружок)
К крынке кошка подошла (“идем” указательным и средним пальцами правой руки)
Сверху сливки попила (указательным пальцем делаем “лакающие” движения в кружке, изображающем горшок)
Глубже сунулась в горшок:
- Молочка напьюсь я впрок! (засовываем палец глубже в кружок-горшок)
Что случилось? Ой-ой-ой!
Кошка крутит головой (крутим головой)
Налакалась молочка –
Не уйти ей из горшка! (пытаемся вытянуть палец из кружка)
С головы горшок не слез.
С ним и убежала в лес! (топоча пальцами обеих рук по столу, показываем как кошка убежала)

“Заяц и кочан капусты”
Встал зайчишка рано (кончики пальцев одной руки образуют мордочку, а выпрямленные указательный и средний пальцы – уши)
Вышел на поляну.
По опушке к лесу шел (идем пальцами по столу)
И большой кочан нашел (кулак другой руки)
Стал зайчишка с хрустом
Грызть кочан капусты:
Хруст-хруст, хруст-хруст… (“заяц” грызет кулак и причмокивает)
Съел, свалился под куст
И уснул. Проснулся…
Сладко потянулся (c усилием растопыриваем все пальцы)
А домой добрался –
долго отдувался! (показываем, как заяц движется по столу)

взято из: Гаврина С.Е., Кутявина Н.Л., Топоркова И.Г., Щербинина С.В. “Развиваем руки – чтоб учиться и писать и красиво рисовать”, 1997, Ярославль, “Академия развития”)

“Прятки”
В прятки пальчики играли
И головки убирали
Вот так, вот так,
И головки убирали.

(ритмично сгибаем и разгибаем пальцы. усложнение: поочередно сгибаем каждый пальчик на обеих руках)

 

“Поиграем в пальчики”
Большаку дрова рубить,
А тебе воды носить,
А тебе печь топить,
А тебе тесто месить,
А малышке песни петь.
Песни петь да плясать,
Родных братьев потешать.

(загибать по очереди все пальцы на обеих руках. на последние строчки – шевелить пальцами обеих рук)

 

“Четыре братца”
Идут четыре братца навстречу старшему.
- Здравствуй, большак!
- Здорово, Васька-указка,
Мишка-середка,
Гришка-сиротка,
Да крошка-Тимошка!

(на вторую строчку соединить все пальцы в щепотку, затем соединять поочередно с большим все остальные пальцы)

 

“ЗамОк”
На дверях висит замок.
Кто его открыть не смог?
Мы замочком постучали,
Мы замочек повертели,
Мы замочек покрутили
И открыли!

(на первую строчку – пальцы в “замочек”. затем стучать о стол “замочком”, затем круговые движения “замочком”, и показали ладошки)

 

“Капустка”
Мы капустку рубим-рубим,
Мы капустку солим-солим,
Мы капустку трем-трем,
Мы капустку жмеме-жмем.

(движения прямыми ладонями вверх-вниз, поочередное поглаживание подушечек пальцев, потирать кулачок о кулачок, сжимать и разжимать кулачки)

 

“Пять пальцев”
На моей руке пять пальцев,
Пять хватальцев, пять держальцев.
Чтоб строгать и чтоб пилить,
Чтобы брать и чтоб дарить.
Их нетрудно сосчитать:
Раз, два, три, четыре, пять!

(ритмично сжимать и разжимать кулачки. на счет – поочередно загибать пальцы на обеих ручках)

 

“Повстречались”
Повстречались два котенка: “Мяу-мяу!”,
Два щенка: “Ав-ав!”,
Два жеребенка: “Иго-го!”,
Два тигренка: “Р-р-р!”,
Два быка: “Му!”.
Смотри, какие рога.

(на каждую строчку соединять поочередно пальцы правой и левой рук, начиная с мизинца. на последнюю строчку показать рога, вытянув указательные пальцы и мизинцы)

 

“Прогулка”
Пошли пальчики гулять,
А вторые – догонять.
Третьи пальчики – бегом,
А четвертые пешком.
Пятый пальчик поскакал
И в конце пути упал.

(пальцы обеих рук сжаты в кулачки, на первую строчку – большие пальчики как бы прыжками двигаются по столу. на вторую строчку по столу двигаются указательные пальчики, на третью – движение по столу средних пальцев в быстром темпе, на четвертую – медленное движение безымянных пальцев по поверхности стола, на пятую – мизинцы, на шестую – хлопок обоими кулаками по поверхности стола)

 

“Цветок”
Вырос высокий цветок на поляне,
Утром весенним раскрыл лепестки.
Всем лепесткам красоту и питанье
Дружно дают под землей корешки.

(руки вверх, ладони прижаты друг к другу, развести пальцы, слегка округлить их. на вторую строчку развести пальчики. на третью – ритмичное движение пальцами вместе-врозь. на четвертую – ладони опустить вниз и тыльной стороной прижать друг к другу, пальцы развести)

 

“Дом и ворота”
На поляне дом стоит,
Ну а к дому путь закрыт.
Мы ворота открываем,
В этот домик приглашаем.

(пальцы обеих рук под углом друг к другу широко расставлены, соприкасаются только кончики одноименных пальцев – “крыша”. на вторую строчку – большие пальцы обеих рук поднять вверх, внутреннюю сторону ладоней повернуть к себе, остальные пальцы в горизонтальном положении вместе, кончики средних пальцев соприкасаются. на третью строчку ладони поворачиваются параллельно друг к другу. на четвертую – то же, что на первую)

 

“Руки в стороны”
Руки в стороны, в кулачок,
Разожми и на бочок.
Руки вверх, в кулачок,
Разожми и на бочок.
Руки вниз, в кулачок,
Разожми и на бочок.

(выполнять по тексту)

 

“В гости к пальчику большому”
В гости к пальчику большому
Приходили прямо к дому
Указательный и средний,
Безымянный и последний
Сам мизинчик-малышок
Постучался об порог.
Вместе пальчики-друзья,
Друг без друга им нельзя.

(1строчка - выставляем вверх большие пальцы
2. соединяем под углом кончики пальцев обеих рук
3. поочередно называемые пальцы соединяются с большими на двух руках одновременно. затем вверх выставляются только мизинцы, пальцы сжаты в кулак.
6. кулачки стучат друг о дружку. на последние строчки – ритмичное сжатие пальцев на обеих руках)

 

“Раз, два, три, четыре, пять”
Раз, два, три, четыре, пять,
Будем пальчики считать!
Крепкие, дружные,
Все такие нужные.
На другой руке опять:
Раз, два, три, четыре, пять!
Пальчики быстрые,
Хотя не очень… чистые.

(на первую строчку – по очереди загибаем пальчики на правой руке. на следующие четыре строчки – ритмично сжимать и разжимать кулачки. на шестую строчку – загибать поочередно пальцы на левой руке. на последние строчки – помахать пальчиками обеих рук)

 

“Мои веселые пальчики”
Мои пальчики расскажут,
Все умеют, все покажут.
Пять их на моей руке.
Все делать могут,
Всегда помогут.
Они на дудочке играют,
Мячик бросают,
Белье стирают,
Пол подметают,
Они считают,
Щиплют, ласкают,
Мои пальцы на руке.

(на первые две строчки – поднять руки вверх и поворачивать ладони внутрь и наружу. затем хлопать в ладоши. далее подражать движениям, соответствующим содержанию стихотворения)

 

“Не плачь, куколка моя”
Не плачь, куколка моя,
Остаешься ты одна.
Не могу с тобой играть,
Нужно мне перестирать:
Твои платья и носки,
твои юбки и чулки,
Свитер, варежки, жакет,
Шапочку, цветной берет.
Я налью воды чуток,
В таз насыплю порошок.
Пену снежную взобью,
Постираю и пойду.
Пока светит солнышко,
Натяну веревочку.
К ней одежду прикреплю,
Ветерком все просушу.
Утюгом туда-сюда
Белье быстро глажу я.
Поработали вдвоем,
А теперь и отдохнем.

(на первые четыре строчки имитировать укачивание куклы. затем загибать поочередно пальцы. далее имитировать движения, описанные в стихотворении. на последние строчки положить ладони на стол или на колени)

 

“Пять малышей”
Один малыш качается в саду,
Два малыша купаются в пруду,
Три малыша ползут к дверям в квартире,
А в это дверь стучат еще четыре.
С пятью другими тоже все в порядке:
Им весело, они играют в прятки.
Где притаились, ясно и ежу,
Но я глаза зажмурил и вожу:
“Один, два три…
Два, три , четыре, пять…
Ну, берегитесь: я иду искать!”

показать указательный палец (вверх), опустить вниз, покачать туда-сюда
показать два пальца, с помощью рук изобразить пловца
показать три пальца, имитировать ползание
постучать правой рукой в ладонь левой, показать четыре пальца
ладонь с растопыренными пальцами
закрыть ладонями глаза
руки в замок, большие пальцы на отлете – “нос”, пальцы одной руки –вверх (будто иголки)
закрыть глаза ладонью
поочередно разгибать пальцы
погрозить пальцем, имитировать движения, будто ловим кого-то

помнится из пионерского детства:
У оленя (руки с растопыренными пальцами приставить к голове – рога)
Дом (руками изобразить крышу над головой)
Большой. (раздвинуть руки в стороны, показывая, насколько большой)
Он глядит в свое окно. (одна рука на уровне груди горизонтально, локоть второй на этой руке, подпираем голову ладонью)
Зайка по лесу бежит (имитировать бег)
В дверь к нему стучит:
“Тук-тук, дверь открой, (имитируем стук в дверь)
Там в лесу (кулаком с отогнутым большим пальцем машем через плечо, указывая назад)
Охотник (имитировать прицеливание из ружья)
Злой (скорчить страшную морду)
- Поскорее забегай, (имитировать открывание двери)
Лапку мне давай. (протягиваем руку для рукопожатия)
        </p>
`,
      links: `
        <li href="/#">#Речецветик</li>
        <li href="/#">Галина Ванюхина</li>
        <li href="/#">Образовательная практика</li>
      `
    },
    {
      toggleModal: toggleModal5,
      setIsOpen: setIsOpen5,
      isOpen: isOpen5,
      img: 'img/blog/img.png',
      date: '17 Января 2022',
      header: 'Развитие мелкой моторики пальцев рук',
      content: `                      
        <p>
РАЗВИТИЕ  МЕЛКОЙ  МОТОРИКИ  ПАЛЬЦЕВ  РУК

Тренировка движений пальцев рук является важнейшим фактором, стимулирующим речевое развитие ребен­ка, способствующим улучшению артикуляционной мотори­ки, подготовке кисти руки к письму и, что не менее важно, мощным средством, повышающим работоспособность коры головного мозга.
Работа по развитию кистей рук хорошо проводить систематически по 3-5 мин ежедневно.
Развивать пальчики и кисти рук можно через:
- игры с пальчиками, сопровождающиеся стишками и потешками

 -  пальчиковую   гимна­стику - специальные упражнения без речевого сопровождения, объединенные в комплекс гимнастики для развития мел­кой моторики рук



-  игры   и   действия   с   игрушками   и   предметами: раскладывание пуговиц, палочек, зерен, желудей и т.д., нанизывание бус. колечек, пуговиц на нитку, пришивание, застегивание и расстегивание пуговиц, игры с мозаикой, строительным материалом и т.д

    1) - творчество: лепка из пластилина и глины, раскрашивание картинок, обведение контуров, штриховку, рисование карандашами и красками различными спо­собами (кистью, тампоном, пальцем, свечой и т.д.), работа с ножницами, поделки из природного материала и т.д
 Упражнения для пальчиковой гимнастики
(выполняются сидя или стоя)
1. Сжимание пальцев в кулак и разжимание (по 20 раз)
а)\tладонями вверх;
б)\tладонями вниз;
в)\tобеих рук одновременно;
г)\tправой и левой по очереди.
Выполняется с силой, в сжатом положении фиксируется 2-3 сек. 



2.\tВращение кистями рук (по 10 раз):
а)\tв правую, затем в левую сторону;
б)\tвовнутрь (обеими кистями);
в)\tнаружу.
Можно выполнять упражнения из положения "руки в сто­роны".
3.\tВыгибание кистей рук (по 10-15 раз):
а)\tодновременно;
б)\tправой, левой но очереди

Этот пальчик – папочка,
Этот пальчик – мамочка,
Этот пальчик – дедушка,
Этот пальчик – бабушка,
Ну, а этот пальчик – я,
Вот и вся моя семья!

Мы делили апельсин;
Много нас, а он один. 
Эта долька для ежа,
Эта долька для стрижа,
Эта долька для утят,
Эта долька для котят,
Эта долька для бобра,
А для волка – кожура.        </p>
`,
    },
  ];

  return (
    <>
      <div className="row">
        {articleAddons.map((val, i) => (
          <div
            key={i}
            className="col-md-3 m-15px-tb"
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={i * 200}
          >
            <div className="blog-grid" onClick={articleAddons[i].toggleModal}>
              <div className="blog-img">
                <img src={articleAddons[i].img} alt="статья"></img>
              </div>
              <div className="blog-info">
                <div className="meta">{articleAddons[i].date} - {author}</div>
                <h6>
                  <a>
                    {articleAddons[i].header}
                  </a>
                </h6>
              </div>
            </div>
          </div>
        ))}
      </div>
      {/* End .row */}

      {articleAddons.map((val, i) => (
        <Modal
          key={i}
          isOpen={articleAddons[i].isOpen}
          onRequestClose={articleAddons[i].toggleModal}
          contentLabel="My dialog"
          className="custom-modal"
          overlayClassName="custom-overlay"
          closeTimeoutMS={500}
        >
          <div>
            <button className="close-modal" onClick={articleAddons[i].toggleModal}>
              <img src="/img/cancel.svg" alt="close icon"/>
            </button>
            {/* End close icon */}

            <div className="box_inner">
              <div className="scrollable">
                <div className="blog-grid">
                  <div className="blog-img">
                    <img src={articleAddons[i].img} alt="статья"></img>
                  </div>
                  {/* End blog-img */}
                  <article className="article">
                    <div className="article-title">
                      <h2>
                        {articleAddons[i].header}
                      </h2>
                      <div className="media">
                        <div className="media-body">
                          <label>{author}</label>
                          <span>{articleAddons[i].date}</span>
                        </div>
                      </div>
                    </div>
                    {/* End .article-title */}

                    <div className="article-content" dangerouslySetInnerHTML={{ __html: articleAddons[i].content }}/>
                    {/* End article content */}
                    <ul className="nav tag-cloud" dangerouslySetInnerHTML={{ __html: articleAddons[i].links }}>
                    </ul>
                    {/* End tag */}
                  </article>
                  {/* End Article */}
                </div>
              </div>
            </div>
          </div>
          {/* End modal box news */
          }
        </Modal>
      ))}
    </>
  );
};

export default News;
