import React from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <div
      className={className}
      style={{ ...style, display: "block", background: "black" }}
      onClick={onClick}
    />
  );
}

export default function SimpleSlider() {
  const settings = {
    dots: true,
    arrows: true,
    infinite: true,
    speed: 1200,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
        },
      },
      {
        breakpoint: 420,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };

  const TestimonilContent = [
    {
      imageName: "img.png",
      desc: `Трудность? Проблема? Скука? - Игра!
Отвечайте на трудности и проблемы игрой.


"Игра – это огромное светлое нежное окно, через которое в духовный мир ребенка вливается живительный поток представлений и понятий об окружающем мире. Игра – это искра, зажигающая огонек пытливости и любознательности".`,
      reviewerName: "В.А.Сухомлинский",
      delayAnimation: "",
    },
    {
      imageName: "img_1.png",
      desc: `Не сравнивай ребёнка с другими детьми. Сравнивай его с собой "вчерашним".
      
      
"Педагогика должна ориентироваться не на вчерашний, а на завтрашний день детского развития. 
В основу воспитания должна быть положена личная деятельность ученика, а всё искусство воспитателя должно сводиться только к тому, чтобы направлять и регулировать эту деятельность.
Только то обучение является хорошим, которое забегает вперед развития"`,
      reviewerName: "Л.С.Выготский",
      delayAnimation: "200",
    },
    {
      imageName: "img_2.png",
      desc: `Ключик к сердцу ребёнка - уважение и понимание, общение на равных, не с позиции главного взрослого.
      
      
"Предоставьте  право ребёнку  на свободу выбора. 
В этом помогают вопросы и готовность принять ответ.
Не жди, что твой ребёнок (воспитанник. Вставка моя) будет таким, как ты или таким, как ты хочешь. Помоги ему стать не тобой, а собой"`,
      reviewerName: "Я.Корчак",
      delayAnimation: "600",
    },
    {
      imageName: "img_2.png",
      desc: `Цените красоту каждого мгновения. Не торопите детей жить.
      
      
"Позволим детям упиваться радостью утра и верить. Именно так хочет ребенок. Ему не жаль времени на сказку, на беседу с собакой, на игру в мяч, на подробное рассматривание картинки, на перерисовку буквы, и все это любовно. Он прав"`,
      reviewerName: "Я.Корчак",
      delayAnimation: "800",
    },
    {
      imageName: "img_3.png",
      desc: `Учимся слушать себя и других.
Когда ещё ребёнку знакомиться с собой, со своими первыми чувствами и эмоциями, если не в детстве? 
«Что я чувствую?» «А что можешь чувствовать ты?»


"Помните о том, что эмоции — важная составляющая жизни: Наши эмоции — мощная сила. Если их не контролировать, они становятся самореализующимися пророчествами."`,
      reviewerName: "Р.Кийосаки",
      delayAnimation: "800",
    },
  ];

  return (
    <div className="testimonial-wrapper w-100">
      <Slider {...settings}>
        {TestimonilContent.map((val, i) => (
          <div
            key={i}
            data-aos="fade-up"
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="testimonial-01 media d-flex items-center">
              <div className="avatar">
                <img
                  src={`img/testimonial/${val.imageName}`}
                  alt="review comments"
                ></img>
              </div>
              <div className="media-body" style={{ whiteSpace: "pre-line" }}
              >
                <p style={{ whiteSpace: "pre-line" }}
                >{val.desc}</p>
                <h6>{val.reviewerName}</h6>
                <span>{val.designation}</span>
              </div>
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
}
