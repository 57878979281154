import React from "react";
import SimpleReactLightbox from "simple-react-lightbox";
import Masonry from "react-masonry-css";

import YouTube from 'react-lazyload-youtube';

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
};

const opts = {
  width: '500',
  height: '281',
  playerVars: {},
};

const tabList = [
  { title: "Нескучные будни", href: "Qp_NOUh9oYs", name: 'Я - лидер' },
  { title: "Нескучные будни", href: "JTMdFCh49EI", name: 'Моё хобби' },
  { title: "Нескучные будни", href: "jjm-7ab8q80", name: 'Тёплый подарок' },

];

const _onReady = (event) => {
  // access to player in all event handlers via event.target
  event.target.pauseVideo();
};

const Closer = () => {
  return (
    <SimpleReactLightbox>
      <Masonry
        breakpointCols={breakpointColumnsObj}
        className="my-masonry-grid"
        style={{ minHeight: '200px' }}
        columnClassName="my-masonry-grid_column"
      >
        {tabList.map((val, i) => (
          <div className="feature-box-02 d-flex align-items-center">
            <div className="media-body">
              <h6>{val.name}</h6>
              <YouTube
                videoId={val.href} opts={opts} onReady={_onReady}
              />
            </div>
          </div>
        ))}
      </Masonry>
    </SimpleReactLightbox>
  );
};

export default Closer;
