import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import SimpleReactLightbox from "simple-react-lightbox";
import { SRLWrapper } from "simple-react-lightbox";
import Masonry from "react-masonry-css";

const breakpointColumnsObj = {
  default: 3,
  1100: 3,
  700: 2,
  500: 1,
};

const tabList = ["Дети: радость участия", "Публикации", "Мои достижения", "Повышение квалификации", "Региональные школы", "Сообщество"];
const tabListContent = [
  {
    porftoliItems: [
      {
        img: "childrenAward/img_21.png",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_22.png",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_19.jpg",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_20.jpg",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img.png",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_1.png",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_2.png",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_3.png",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_4.png",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_2.jpg",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_3.jpg",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_5.jpg",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_7.jpg",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_8.gif",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_9.gif",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_10.jpg",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_11.gif",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_12.gif",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_13.gif",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_14.jpg",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_15.gif",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_16.jpg",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_17.jpg",

        delayAnimation: "",
      },
      {
        img: "childrenAward/img_18.gif",

        delayAnimation: "",
      },
    ],
  },
  {
    porftoliItems: [
      {
        img: "publication/img.png",

        delayAnimation: "",
      },
      {
        img: "publication/img_1.png",

        delayAnimation: "",
      },
      {
        img: "publication/img_2.png",

        delayAnimation: "",
      },
      {
        img: "publication/img_3.png",

        delayAnimation: "",
      },
    ],
  },
  {
    porftoliItems: [
      {
        img: "competition/21.png",

        delayAnimation: "",
      },
      {
        img: "competition/22.png",

        delayAnimation: "",
      },
      {
        img: "competition/23.png",

        delayAnimation: "",
      },
      {
        img: "competition/24.png",

        delayAnimation: "",
      },
      {
        img: "competition/25.png",

        delayAnimation: "",
      },
      {
        img: "competition/18.jpg",

        delayAnimation: "",
      },
      {
        img: "competition/1.jpg",

        delayAnimation: "",
      },
      {
        img: "competition/4.jpg",

        delayAnimation: "",
      },
      {
        img: "competition/5.jpg",

        delayAnimation: "",
      },
      {
        img: "competition/7.png",

        delayAnimation: "",
      },
      {
        img: "competition/8.jpg",

        delayAnimation: "",
      },
      {
        img: "competition/9.png",

        delayAnimation: "",
      },
      {
        img: "competition/10.png",

        delayAnimation: "",
      },
      {
        img: "competition/13.jpg",

        delayAnimation: "",
      },
      {
        img: "competition/14.jpg",

        delayAnimation: "",
      },
      {
        img: "competition/15.gif",

        delayAnimation: "",
      },
      {
        img: "competition/16.jpg",

        delayAnimation: "",
      },
      {
        img: "competition/17.png",

        delayAnimation: "",
      },
    ],
  },
  {
    porftoliItems: [
      {
        img: "qualification/1.gif",

        delayAnimation: "",
      },
      {
        img: "qualification/2.jpg",

        delayAnimation: "",
      },
      {
        img: "qualification/3.jpg",

        delayAnimation: "",
      },
      {
        img: "qualification/4.jpg",

        delayAnimation: "",
      },
      {
        img: "qualification/5.jpg",

        delayAnimation: "",
      },
      {
        img: "qualification/6.jpg",

        delayAnimation: "",
      },
      {
        img: "qualification/7.jpg",

        delayAnimation: "",
      },
      {
        img: "qualification/8.gif",

        delayAnimation: "",
      },
      {
        img: "qualification/11.jpg",

        delayAnimation: "",
      },
      {
        img: "qualification/12.jpg",

        delayAnimation: "",
      },
      {
        img: "qualification/13.jpg",

        delayAnimation: "",
      },
      {
        img: "qualification/14.jpg",

        delayAnimation: "",
      },
    ],
  },
  {
    porftoliItems: [
      {
        img: "regional/11.png",

        delayAnimation: "",
      },
      {
        img: "regional/12.png",

        delayAnimation: "",
      },
      {
        img: "regional/13.png",

        delayAnimation: "",
      },
      {
        img: "regional/14.png",

        delayAnimation: "",
      },
      {
        img: "regional/15.png",

        delayAnimation: "",
      },
      {
        img: "regional/16.png",

        delayAnimation: "",
      },
      {
        img: "regional/1.jpg",

        delayAnimation: "",
      },
      {
        img: "regional/2.jpg",

        delayAnimation: "",
      },
      {
        img: "regional/3.jpg",

        delayAnimation: "",
      },
      {
        img: "regional/4.jpg",

        delayAnimation: "",
      },
      {
        img: "regional/5.jpg",

        delayAnimation: "",
      },
      {
        img: "regional/6.jpg",

        delayAnimation: "",
      },
      {
        img: "regional/7.jpg",

        delayAnimation: "",
      },
      {
        img: "regional/8.png",

        delayAnimation: "",
      },
      {
        img: "regional/9.png",

        delayAnimation: "",
      },
    ],
  },
  {
    porftoliItems: [
      {
        img: "summer/31.jpg",

        delayAnimation: "",
      },
      {
        img: "summer/32.jpg",

        delayAnimation: "",
      },
      {
        img: "summer/33.jpg",

        delayAnimation: "",
      },
      {
        img: "summer/34.jpg",

        delayAnimation: "",
      },
      {
        img: "summer/35.jpg",

        delayAnimation: "",
      },
      {
        img: "summer/36.jpg",

        delayAnimation: "",
      },
      {
        img: "summer/1.jpg",
      },
      {
        img: "summer/2.jpg",
      },
      {
        img: "summer/3.jpg",
      },
      {
        img: "summer/4.jpg",
      },
      {
        img: "summer/5.jpg",
      },
      {
        img: "summer/6.jpg",
      },
      {
        img: "summer/7.jpg",
      },
      {
        img: "summer/8.jpg",
      },
      {
        img: "summer/9.jpg",
      },
      {
        img: "summer/10.jpg",
      },
      {
        img: "summer/11.jpg",
      },
      {
        img: "summer/12.jpg",
      },
      {
        img: "summer/13.jpg",
      },
      {
        img: "summer/14.jpg",
      },
      {
        img: "summer/15.jpg",
      },
      {
        img: "summer/16.jpg",
      },
      {
        img: "summer/17.jpg",
      },
      {
        img: "summer/18.jpg",
      },
      {
        img: "summer/19.jpg",
      },
      {
        img: "summer/20.jpg",
      },
      {
        img: "summer/21.jpg",
      },
      {
        img: "summer/22.jpg",
      },
      {
        img: "summer/23.jpg",
      },
      {
        img: "summer/24.jpg",
      },
      {
        img: "summer/25.jpg",
      },
    ],
  },
];

const Portfolio = () => {
  return (
    <SimpleReactLightbox>
      <div className="portfolio-filter-01">
        <Tabs>
          <TabList className="filter d-flex flex-wrap justify-content-start">
            {tabList.map((val, i) => (
              <Tab key={i}>{val}</Tab>
            ))}
          </TabList>
          {/* End tablist */}

          <SRLWrapper>
            {tabListContent.map((tabContent, i) => (
              <TabPanel key={i}>
                <div className="portfolio-content ">
                  <Masonry
                    breakpointCols={breakpointColumnsObj}
                    className="my-masonry-grid"
                    columnClassName="my-masonry-grid_column"
                  >
                    {tabContent.porftoliItems.map((val, i) => (
                      <div
                        className="portfolio-box-01"
                        key={i}
                        data-aos="fade-right"
                        data-aos-duration="1200"
                        data-aos-delay="0"
                      >
                        <div className="portfolio-img">
                          <div className="portfolio-info">
                            <span>{val.title}</span>
                            <br/>
                            <span>{val.subTitle}</span>
                          </div>
                          {/* End .portfolio-info */}
                          <a
                            href={`img/portfolio/${val.img}`}
                            className="gallery-link"
                          >
                            <img
                              src={`img/portfolio/${val.img}`}
                              alt={val.alterText}
                            />
                          </a>
                        </div>
                      </div>
                    ))}
                  </Masonry>
                </div>
                {/* End list wrapper */}
              </TabPanel>
            ))}
          </SRLWrapper>
          {/* End tabpanel */}
        </Tabs>
      </div>
    </SimpleReactLightbox>
  );
};

export default Portfolio;
