import React from "react";

const Teachers = () => {
  return (
    <>
      <section id="teachers">
        <div className="container">
          <div className="row g-5">
            <div className="offset-3 col-6 p-2 border border-3">

              <figure className=""><a href="https://universityofchildhood.ru/" target="_blank"
                                                    rel="noopener"><img
                src="img/pedagog/1.jpg"
                alt="rybakovfond"/></a></figure>
              <strong>Уважаемые педагоги!</strong>
              <p>Предлагаю Вашему вниманию проекты сообщества <a target="_blank"
                                                                 rel="noopener" href={'https://universityofchildhood.ru/'}>«Университет Детства»</a>,
                в которых мы с детьми участвуем</p>
            </div>

            <div className="col-lg-6 col-12 p-2 border border-3">

              <figure className="wp-block-image"><a href="https://doshkolka.rybakovfond.ru/4d" target="_blank"
                                                    rel="noopener"><img
                src="img/pedagog/2.jpg"
                alt="rybakovfond"/></a></figure>
              <strong>Международный Проект «ИГРА 4D: Дети, Движение, Дружба, Двор»</strong>

              <p>Вы задумываетесь о роли игры в развития ребёнка? Вы тоже считаете, что дети мало играют? Вы хотите
                воспитывать счастливых детей?</p>

              <p>Присоединяйтесь к Международному Проекту «ИГРА 4D: Дети, Движение, Дружба, Двор» - большое сообщество
                единомышленников по возрождению детской игры! </p>

              <p><strong>Цель проекта:</strong> способствовать возрождению культуры детской подвижной игры через создание сообщества
                единомышленников для организации подвижных игр с дошкольниками.</p>

              <p>Проект является инициативой команды педагогов из шести регионов - победителей международного Конкурса
                грантов им.Л.С.Выготского, участников Летней школы, был разработан и реализуется при поддержке и участии
                фонда «Университет детства» Российской Федерации (г. Москва), это уникальный марафон детских дворовых
                игр, помогающий каждому ребёнку полноценно и активно проживать счастливый период детства.</p>

              <p>Проект прошел 4 этапа отбора среди тысяч других заявок и объявлен в числе 100 лучших мировых инноваций
                в образовании. Более 600 экспертов из 100 стран приняли участие в отборе и формировании списков
                победителей.</p>

              <p>ПРАВИЛА УЧАСТИЯ В ПРОЕКТЕ:</p>

              <p>В проекте есть база для формирования игрового сообщества педагогов «Банк игр». Участники проекта (наши
                дети) ежемесячно изучают правила двух подвижных игр - «Игру месяца» и «Игру по выбору» из банка проекта,
                после чего игры проводятся на улице.</p>
              <p>Играйте вместе с нами</p>
            </div>

            <div className="col-lg-6 col-12 p-2 border border-3">

              <figure className="wp-block-image"><a href="https://universityofchildhood.ru/knigabum" target="_blank"
                                                    rel="noopener"><img
                src="img/pedagog/5.jpg"
                alt="nud"/></a></figure>
              <strong>Всероссийский проект «КнигаБум»</strong>
              <p>Как выбрать из множества книг именно ту, которая найдет отклик в душе вашего ребенка?  Сделать выбор помогут буктрейлеры, созданные детьми. </p>

              <p>Проект  является инициативой творческой группы педагогов МАДОУ № 9 «Березка» г. Снежногорска.  победителей  международного Конкурса  грантов им.Л.С.Выготского,  участников  Летней школы. </p>
              <p><strong>Цель проекта:</strong> популяризация чтения, привлечение внимания к книгам при помощи буктрейлера.</p>

              <p>Как создать буктрейлер всего за 4 шага?</p>
              <p>Какие конкурсы, викторины, акции… предлагает нам КнигаБумная беседка?</p>
              <p>Об этом вы прочитаете на сайте проекта:</p>
              <p>Присоединяйтесь!</p>


            </div>

            <div className="col-lg-6 col-12 p-2 border border-3">

              <figure className="wp-block-image"><a href="https://universityofchildhood.ru/cherdakvremeni" target="_blank"
                                                    rel="noopener"><img
                src="img/pedagog/4.jpg"
                alt="Чердак"/></a></figure>
              <strong>Всероссийский проект «Чердак Времени»</strong>
              <p>Как сделать детский сад местом необыкновенных открытий для маленького человека? Как передать подрастающему поколению историю, ценности, традиции, особенности быта и обычаи народа?</p>

              <p><strong>Цель проекта:</strong>  поиск эффективных форм организации различных видов деятельности для развития навыков будущего через знакомство детей с предметами прошлого</p>

              <p>Вместе мы будем развивать у дошкольников навыки коммуникации, эмоционального интеллекта, правильной речи, творческого мышления, которые в дальнейшем помогут им решать разные жизненные задачи.</p>

              <p>Присоединяйтесь! Создайте в ДОУ Чердак Времени — пространство для совместного творчества и общения детей и взрослых, особое место, где можно слушать интересные истории, фантазировать, изучать предметы старины и, наоборот, придумывать новую жизнь старым вещам, хранить ценные находки.</p>

              <p>Об участии в проекте, интересных заданиях, конкурсах, бонусах Вы узнаете, пройдя по ссылке:</p>
            </div>



            <div className="col-lg-6 col-12 p-2 border border-3">

              <figure className="wp-block-image"><a href="https://universityofchildhood.ru/cherdakvremeni" target="_blank"
                                                    rel="noopener"><img
                src="img/pedagog/3.jpg"
                alt="Чердак"/></a></figure>
              <strong>Приглашаю Вас в Народный Университет Детства</strong>
              <p>Активные педагоги, использующие в своей работе технологии и практики дошкольного образования, ориентированные на семью, делятся   опытом  реализации проектов, методик, технологий. </p>

              <p>Участвуйте, если Вы используете интересные инструменты, и у Вас есть чем поделиться с сообществом педагогов.</p>

              <p>Нажмите на баннер, чтобы узнать подробнее:</p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Teachers;
