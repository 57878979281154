import React from "react";
import MapAnimation from './MapAnimation';

const Map = () => {
  return (
    <>
      <section id="resume" className="section">
        <div className="container">
          <div className="title">
            <div className={'m-15px-tb'}>
              <h3>Педагогическая находка</h3>
            </div>
            <div>
              <h4>Карта приключений как образовательная модель, направленная на преодоление у детей речевых нарушений.<br/><br/>
                В чём ценность?</h4>
            </div>
          </div>

          <MapAnimation/>
        </div>
      </section>
    </>
  );
};

export default Map;
