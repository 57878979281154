import React from "react";
import Closer from './closer';

const AwardContnet = [
  {
    awardFor: 'Умею быстро в уме переворачивать слова "наоборот" и так разговаривать, даже рассказывать сказки ',
    delayAnimation: "0",
  },
  {
    awardFor: 'В нашей семье  четверо детей, это счастье',
    delayAnimation: "600",
  },
  {
    awardFor: 'Я нарисовала в кабинете дерево букв  на стене и поняла, что я художник в душе)',
    delayAnimation: "400",
  },
  {
    awardFor: 'В детстве я рисовала человечков,  и представляла, что это девочки и мальчики, я с ними общалась, мы придумывали разные приключения, даже целый город у нас был нарисованный, а там – сюрпризы, волшебство',
    delayAnimation: "200",
  },
  {
    awardFor: 'Я лауреат Фестиваля самодеятельного творчества работников образования Красноярского края «Творческая встреча» в номинации  «Инструментальная музыка»',
    delayAnimation: "400",
  },
  {
    awardFor: 'Мне интересно общаться с детьми, погружаться в их необыкновенный мир, совершать с ними увлекательные открытия, черпать вдохновение, наверное, потому, что  я в душе  тоже  ребёнок, только немного подросший',
    delayAnimation: "200",
  },
  {
    awardFor: 'Самый огромный торт, который я испекла, в высоту был 20 см, диаметром 35 см, и очень вкусный',
    delayAnimation: "600",
  },
  {
    awardFor: 'Согласна с Пабло Пикассо, что "Искусство смахивает с души пыль повседневной жизни" ',
    delayAnimation: "600",
  },
  {
    awardFor: 'Могу подобрать вторую партию к любой песне на ходу, и подпевать исполнителю. Параллельно могу придумать и третью) ',
    delayAnimation: "0",
  },
];

const Facts = () => {
  return (
    <>
      <div className="row">
        {AwardContnet.map((val, i) => (
          <div
            className="col-lg-4 m-15px-tb"
            key={i}
            data-aos="fade-right"
            data-aos-duration="1200"
            data-aos-delay={val.delayAnimation}
          >
            <div className="feature-box-02 d-flex align-items-center">
              <div className="media-body">
                <h6>{val.awardName}</h6>
                <p>{val.awardFor}</p>
              </div>
            </div>
          </div>
          // End .col
        ))}
      </div>
      <div className="row">
        <div className="col-12">
          <div className="feature-box-02 d-flex align-items-center">
            <div className="media-body">
              <p>{
               `Я родилась в тот же день, что и А.С Пушкин, он мой любимый поэт. Мне очень нравятся его прекрасные строки:
               «Есть Книга, в которой каждое слово истолковано, объяснено, проповедано во всех концах земли, применено ко всевозможным обстоятельствам жизни и происшествиям мира...
               Книга сия называется Евангелием — и такова её вечно новая прелесть, что если мы, пресыщенные миром или удручённые унынием,
               случайно откроем её, то уже не в силах противиться её сладостному влечению»`
              }</p>
            </div>
          </div>
          <Closer></Closer>
        </div>
      </div>
      {/* End .row */}
    </>
  );
};

export default Facts;
