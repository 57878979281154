import React from "react";

const ContactInfo = () => {
  return (
    <>
      <div className="contact-info">
        <h4>Есть вопросы? Напишите мне</h4>
        <p>
          Всегда рада ответить на вопросы,
          получить конструктивную критику или предложение.
        </p>

        <ul>
          <li className="media">
            <i className="icon icon-map"></i>
            <span className="media-body">
              МБДОУ Балахтинский детский сад № 5 «Солнышко».
            </span>
          </li>
          {/* End li */}

          <li className="media">
            <i className="icon icon-envelope"></i>
            <a href="mailto:allazinareva@mail.ru">
              <span>allazinareva@mail.ru</span>
            </a>
          </li>
          {/* End li */}

          <li className="media">
            <i className="icon icon-phone"></i>
            <a href="tel:+7 913-175-2755"><span className="media-body">+7 913 175 2755</span></a>
          </li>
          {/* End li */}
        </ul>
      </div>
      {/* End .contact-info */}
    </>
  );
};

export default ContactInfo;
